import { useState } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import TextInput from "../../components/controls/text-input";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  requiredValidator,
  duplicateValidator,
} from "../../helpers/validators";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { useAppSelector } from "../../hooks/useAppSelector";
import Dropdown from "../../components/controls/dropdown";
import { SelectChangeEvent } from "@mui/material";
import { showSuccess } from "../../components/notification/toastr-actions";
import { MainContent, Footer, ButtonGroup } from "../../styles/shared/drawer";
import { ApplicationCategory } from "../../models/product/application-category";
import {
  insertCategory,
  updateCategory,
} from "../../store/features/product/product-category-slice";

interface Validation {
  name: string | null;
  departmentCategoryId: string | null;
}
export default function ProductCategoryDrawer() {
  const dispatch = useAppDispatch();
  const [validation, setValidation] = useState<Validation>({
    name: null,
    departmentCategoryId: null,
  });
  const categories = useAppSelector(
    (state) => state.productCategory.categories
  );
  const selectedCategory = useAppSelector(
    (state) => state.productCategory
  ).selectedCategory;

  const departmentCategories = useAppSelector(
    (state) => state.department.departmentCategories
  );

  const defaultCategory: ApplicationCategory = {
    id: 0,
    departmentCategoryId: 0,
    name: "",
    expand: false,
    departmentCategoryName: "",
    productCount: 0,
  };

  const dropdownOptions = (
    departmentCategories?.departmentCategories || []
  ).map((category) => ({
    value: category.id.toString(),
    label: category.name,
  }));

  const [category, setCategory] = useState<ApplicationCategory>(
    selectedCategory || defaultCategory
  );

  const onChange = <K extends keyof ApplicationCategory>(
    value: ApplicationCategory[K],
    property: K,
    validation?: (input: ApplicationCategory[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;

    setCategory((prevState) => ({
      ...prevState,
      [property]: value,
    }));

    setValidation((prevState) => ({
      ...prevState,
      [property]: errorMessage,
    }));
  };

  const handleDepartmentCategorySelection = (
    event: SelectChangeEvent<unknown>
  ) => {
    const {
      target: { value },
    } = event;
    const selectedValue = value as string;

    setCategory((prevState) => ({
      ...prevState,
      departmentCategoryId: parseInt(selectedValue),
    }));

    setValidation((prevState) => ({
      ...prevState,
      departmentCategoryId: requiredValidator(selectedValue),
    }));
  };

  const onUpdate = async () => {
    if (!validateAndCheckErrors()) {
      return;
    }
    if (category.id === 0) {
      const categoryNameError = duplicateValidator(
        categories?.categories || [],
        "name",
        category.name || "",
        category.id
      );
      if (categoryNameError) {
        setValidation((prevState) => ({
          ...prevState,
          name: categoryNameError,
        }));
        return;
      }
    }
    let notificationMessage = `${category.name} `;
    if (category.id == 0) {
      await dispatch(insertCategory(category));
      notificationMessage += "created";
    } else {
      await dispatch(updateCategory(category));
      notificationMessage += "updated";
    }
    dispatch(showSuccess(notificationMessage));
    dispatch(closeDrawer());
  };

  const validateAndCheckErrors = () => {
    const validation = {
      name: requiredValidator(category.name),
      departmentCategoryId: requiredValidator(category.departmentCategoryId),
    };
    setValidation(validation);
    const hasErrors = Object.values(validation).some((error) => error !== null);
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <MainContent>
        <Container>
          <TextInput
            label="Name"
            id="NameInput"
            value={category.name}
            error={validation.name}
            onChange={(event) =>
              onChange(event.target.value, "name", (input) =>
                requiredValidator(input)
              )
            }
          />
          <Dropdown
            label="Department category"
            id="DepartmentCategoryDropdown"
            value={category.departmentCategoryId}
            errorMessage={validation.departmentCategoryId}
            options={dropdownOptions}
            variant="outlined"
            onChange={handleDepartmentCategorySelection}
            autoWidth={false}
            multiple={false}
            native={false}
          />
        </Container>
      </MainContent>
      <Footer>
        <ButtonGroup>
          <Button
            label="Cancel"
            variant="outlined"
            id="btnCancelDrawer"
            onClick={() => dispatch(closeDrawer())}
          />
          <Button
            id="btnCreate"
            label={category.id == 0 ? "Create" : "Update"}
            variant="contained"
            onClick={onUpdate}
          />
        </ButtonGroup>
      </Footer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
