import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastrState {
    notifications: ToastrNotification[];
}

interface ToastrNotification {
    id: string;
    type: 'success' | 'error' | 'info' | 'warning';
    message: string;
}

const initialState: ToastrState = {
    notifications: []
};

const toastrSlice = createSlice({
    name: 'toastr',
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<ToastrNotification>) => {
            state.notifications.push(action.payload);
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.notifications = state.notifications.filter(notification => notification.id != action.payload);
        }
    }
});

export const { addNotification, removeNotification } = toastrSlice.actions;

export default toastrSlice.reducer;
