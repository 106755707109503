import { Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import styled from 'styled-components';
import TextInput from '../../components/controls/text-input';
import Button from '../../components/controls/button';
import { LargeSpacer, MediumSpacer } from '../../styles/shared/spacing';
import BrandLogo from '../../components/brand-logo';
import { emailValidator, matchValidator, requiredValidator } from '../../helpers/validators';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApplicationRole } from '../../models/authentication/application-roles';
import AnchorLink from '../../components/controls/anchor-link';
import { sendResetLink, setConfirmPassword, setEmail, setErrors, setPassword, setToken } from '../../store/features/authentication/reset-slice';
import { error } from 'console';
import PasswordInput from '../../components/controls/password-input';

export default function Reset() {
    const userState = useAppSelector((state => state.user));
    const resetState = useAppSelector((state => state.reset));
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        var token = searchParams.get("token")
        if (token) {
            dispatch(setToken(token))
        }
    }, [searchParams])


    // log the user in if the user object has been set.
    useEffect(() => {
        if (userState.user) {
            if (userState.user.role == ApplicationRole.SupplierManager) {
                navigate("/supplier");
            } else {
                navigate("/");
            }
        }
    }, [userState])

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(setEmail(event.target.value));
        dispatch(setErrors({ ...resetState.errors, email: emailValidator(value) }));
    };


    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(setPassword(value));
        dispatch(setErrors({ ...resetState.errors, password: requiredValidator(value), confirmPassword: null, }));
    };

    const handleConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(setConfirmPassword(value));
        dispatch(setErrors({ ...resetState.errors, confirmPassword: matchValidator(value, resetState.newPassword, false, "The passwords must match") }));
    };

    const handleSubmit = async () => {
        const emailError = emailValidator(resetState.email);
        const passwordError = resetState.token ? requiredValidator(resetState.newPassword) : null;
        const confirmPasswordError = resetState.token ? matchValidator(resetState.confirmPassword, resetState.newPassword, false, "The passwords must match") : null;

        const hasErrors = emailError || passwordError || confirmPasswordError;

        dispatch(setErrors({
            email: emailError,
            password: passwordError,
            pageError: null,
            confirmPassword: confirmPasswordError
        }));

        if (!hasErrors) {
            dispatch(sendResetLink());
        }
    };


    return (
        <Background>
            <LoginContainer>
                <LoginForm>
                    <LogoContainer>
                        <BrandLogo size="large" />
                        <AppTitle>Ordering Portal</AppTitle>
                    </LogoContainer>
                    <LargeSpacer />
                    <AnchorLink id="btnBack" href='/login' label='Back to login' />
                    <MediumSpacer />
                    {resetState.errors.pageError && <Alert severity="error">{resetState.errors.pageError}</Alert>}
                    <MediumSpacer />

                    {!resetState.token && (
                        resetState.emailSent ?
                            <Alert severity="success">If you have an account, you will be sent instructions to reset your password.</Alert>
                            : (
                                <>
                                    <TextInput id='txtEmail' label='Email' error={resetState.errors.email} onChange={handleEmailChange} value={resetState.email} />
                                    <LargeSpacer />
                                    <Button id="btnRequest" variant="contained" label='Request password' fullWidth loading={resetState.loading} isPrimary onClick={handleSubmit} />
                                </>
                            )
                    )}

                    {resetState.token && (

                        resetState.passwordChanged ? <Alert severity="success">Password changed succesfully</Alert> :
                            (
                                <>
                                    <TextInput id='txtEmail' label='Email' error={resetState.errors.email} onChange={handleEmailChange} value={resetState.email} />
                                    <LargeSpacer />
                                    <PasswordInput id='txtPassword' onChange={handlePasswordChange} error={resetState.errors.password} value={resetState.newPassword ?? ""} label='New password' />
                                    <LargeSpacer />
                                    <PasswordInput id='txtConfirmPassword' onChange={handleConfirm} error={resetState.errors.confirmPassword} value={resetState.confirmPassword ?? ""} label='Confirm password' />
                                    <LargeSpacer />
                                    <Button id="btnChangePassword" variant="contained" label='Change password' fullWidth loading={resetState.loading} isPrimary onClick={handleSubmit} />
                                </>
                            )


                    )}
                </LoginForm>
            </LoginContainer>
        </Background>
    )
}

const Background = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.application.login.background};
    height: 100vh;
    width: 100vw;
`;

const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.application.login.foreground};
    border-radius: 8px;
    box-shadow: ${props => props.theme.application.boxShadow};
    width: 270px;
    padding: 40px;    


`;



const LoginForm = styled.div`
    width: 100%;
    text-align: center;
`;

const LogoContainer = styled.div`
    background: ${props => props.theme.application.logoBackground}; 
    padding:10px;
    border-radius:8px;
    box-shadow: ${props => props.theme.application.boxShadow};   
`
const AppTitle = styled.div`
    color: ${props => props.theme.application.textContrast}; 
    font-size:1.5rem;
`



