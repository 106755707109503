import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import Header from "../../components/layout/header";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import styled from "styled-components";
import Back from "../../components/layout/back";
import {
  ActiveArea,
  ContentArea,
  HeaderArea,
  Main,
  Title,
  TitleArea,
  VerticalContent,
} from "../../styles/shared/page";
import ModalHandler from "../modals/modal-handler";
import Toastr from "../../components/notification/toastr";
import { useErrorDisplay } from "../../hooks/useErrorDisplay";
import Button from "../../components/controls/button";
import { createOrder } from "../../store/features/ordering/order-slice";
import { useNavigate } from "react-router-dom";
import { ApplicationOrder } from "../../models/order/application-order";
import ConfirmModal from "../../components/notification/confirm-modal";
import DrawerHandler from "../drawer/drawer-handler";

interface LayoutProps {
  children: React.ReactNode;
}

const BasketLayout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user).user;
  const branch = useAppSelector((state) => state.branch).selectedBranch;
  const basket = useAppSelector((state) => state.basket).basket;
  const order = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useErrorDisplay();

  const createOrderFromBasket = async () => {
    if (basket?.id && basket.itemCount > 0) {
      var createdOrder = await dispatch(createOrder(basket.id));
      var order = createdOrder.payload as ApplicationOrder;
      navigate(`/order/${order.id}/confirmation`);
    }
  };

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <DrawerHandler></DrawerHandler>
      <ConfirmModal></ConfirmModal>
      <Header name={user?.name} team={user?.role} selectedBranch={branch} />
      <VerticalContent>
        <Back></Back>
        <HeaderArea>
          <TitleArea>
            <Title>
              Basket ({basket?.itemCount})
              {basket?.id && basket.itemCount > 0 && (
                <Button
                  id="btnCompleteOrder"
                  label="complete order"
                  variant="contained"
                  onClick={createOrderFromBasket}
                  loading={order.loading}
                  fullWidth={false}
                ></Button>
              )}
            </Title>
            <Buttons></Buttons>
          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>{children}</ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default BasketLayout;

const Buttons = styled.div`
  button {
    margin-right: 30px;
  }
`;
