import { ApplicationError } from "../../models/errors/application-error";
import IProductService from "./product-service-interface";
import { ApplicationCategoryList } from "../../models/product/application-category-list";
import { ApplicationProductList } from "../../models/product/application-product-list";
import { ApplicationProductDetail } from "../../models/product/application-product-detail";
import { ApplicationCategory } from "../../models/product/application-category";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationBestSupplierProduct } from "../../models/product/application-best-supplier-product";
import { ApplicationDay, ApplicationDeliveryDay } from "../../models/supplier/application-day";
import client from "../../external/open-api";
import { components } from "../../external/schema/order-link-api-schema";
import { ApplicationProduct } from "../../models/product/application-product";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";

export default class ProductService implements IProductService {
  async getCategoriesByDepartment(
    departmentId: number,
    currentPosition: number,
    fetchNext: number
  ): Promise<ApplicationCategoryList> {
    const { data, error } = await client.GET(
      "/v{version}/api/department/{departmentId}/categories",
      {
        params: {
          path: { version: "1", departmentId }, // Path parameter
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": false,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch categories");
    }

    return {
      categories: this.mapToCategories(data.data, departmentId),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async searchSupplierProducts(
    supplierId: number,
    searchText: string,
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean
  ): Promise<ApplicationProductList> {
    const { data, error } = await client.GET(
      "/v{version}/api/product/supplier/{supplierId}",
      {
        params: {
          path: { version: "1", supplierId }, // Path parameter
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": fetchAll,
            searchText: searchText,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to search products");
    }

    const products = this.mapToApplicationProduct(data.data);

    return {
      categoryId: -99,
      products,
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async searchProducts(
    branchId: number,
    departmentId: number,
    searchText: string,
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean
  ): Promise<ApplicationProductList> {
    const { data, error } = await client.GET(
      "/v{version}/api/product/branch/{branchId}/department/{departmentId}/search",
      {
        params: {
          path: { version: "1", branchId, departmentId }, // Path parameter
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": fetchAll,
            searchText: searchText,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to search products");
    }

    const products = this.mapToApplicationProduct(data.data);

    return {
      categoryId: -99,
      products,
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async getProducts(
    branchId: number,
    categoryId: number,
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean
  ): Promise<ApplicationProductList> {
    const { data, error } = await client.GET(
      "/v{version}/api/product/branch/{branchId}/category/{categoryId}",
      {
        params: {
          path: { version: "1", branchId, categoryId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": fetchAll,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to search products");
    }
    const products = this.mapToApplicationProduct(data.data);

    return {
      categoryId: categoryId,
      products,
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async getProductsByDepartmentCategory(
    branchId: number,
    departmentCategoryId: number,
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean
  ): Promise<ApplicationProductList> {
    const { data, error } = await client.GET(
      "/v{version}/api/product/branch/{branchId}/departmentcategory/{departmentCategoryId}",
      {
        params: {
          path: { version: "1", branchId, departmentCategoryId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": fetchAll,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError(
        "Failed to get products by department category"
      );
    }

    const products = this.mapToApplicationProduct(data.data);

    return {
      categoryId: departmentCategoryId,
      products: products,
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async getProduct(
    productId: number,
    branchId: number
  ): Promise<ApplicationProductDetail> {
    const { data, error } = await client.GET(
      "/v{version}/api/product/{productId}/branch/{branchId}",
      {
        params: {
          path: { version: "1", productId, branchId },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to get product");
    }

    return {
      id: data.data?.id ?? 0,
      name: data.data?.name ?? "",
      description: data.data?.description ?? "",
      categoryId: data.data?.categoryId ?? 0,
      image: data.data?.image ?? "",
      plu: data.data.plu ?? "",
      active: data.data.active ?? false,
      information: data.data?.productInformation ?? {},
      suggestedQuantity: data.data?.suggestedQuantity ?? 0,
      supplierSizeUnit: data.data?.supplierSizeUnit
        ? mapStringToEnum(ApplicationSizeUnit, data.data.supplierSizeUnit)
        : undefined,
      branches:
        data.data.branches?.map(
          (branch: components["schemas"]["BranchDTO"]) => ({
            id: branch.id ?? 0,
            name: branch.name ?? "",
          })
        ) ?? [],
    };
  }

  async upsertBranchProduct(
    branchId: number,
    productId: number,
    active: boolean
  ): Promise<void> {
    const { data, error } = await client.POST(
      "/v{version}/api/product/branchproduct",
      {
        params: {
          path: { version: "1" },
        },
        body: {
          branchId,
          productId,
          active,
        },
      }
    );

    if (!data || error) {
      throw new ApplicationError("Failed to get product");
    }
  }

  async getBestSupplierProduct(
    productId: number,
    branchId: number
  ): Promise<ApplicationBestSupplierProduct> {
    const { data, error } = await client.GET(
      "/v{version}/api/product/branch/{branchId}/product/{productId}/best",
      {
        params: {
          path: { version: "1", branchId, productId },
        },
      }
    );

    if (!data?.success || error) {
      throw new ApplicationError("Unable to find product for any supplier");
    }

    return {
      supplierId: data.data?.supplier?.id ?? 0,
      supplierName: data.data?.supplier?.name ?? "",
      supplierAddressLine1: data.data?.supplier?.addressLine1 ?? "",
      supplierAddressLine2: data.data?.supplier?.addressLine2 ?? "",
      supplierAddressLine3: data.data?.supplier?.addressLine3 ?? "",
      supplierCity: data.data?.supplier?.city ?? "",
      supplierPostcode: data.data?.supplier?.postcode ?? "",
      deliveryDay: this.mapDeliveryDay(data.data?.deliveryDay ?? null),
      price: data.data?.supplierProduct?.price ?? 0,
      size: data.data?.supplierProduct?.size ?? 0,
      sizeUnit: mapStringToEnum(
        ApplicationSizeUnit,
        data.data?.supplierProduct?.sizeUnit ?? "Unknown"
      ),
    };
  }

  private mapDeliveryDay(data: components["schemas"]["DeliveryDayDTO"] | null,): ApplicationDeliveryDay | undefined {
    if (!data) {
      return undefined;
    }

    return {
      date: data?.date ? new Date(data?.date) : new Date(),
      day: mapStringToEnum(ApplicationDay, data?.day ?? "Monday")
    }
  }
  private mapToCategories(
    data: components["schemas"]["GetCategoriesResponse"] | null,
    departmentId: number
  ): ApplicationCategory[] {
    if (!data) {
      return [];
    }

    return (
      data.categories?.map((d) => ({
        id: d.id ?? 0,
        departmentId: departmentId,
        departmentCategoryId: d.departmentCategoryId ?? 0,
        name: d.name ?? "",
        expand: false,
      })) || []
    );
  }

  private mapToApplicationProduct(
    data: components["schemas"]["GetProductsResponse"] | undefined
  ): ApplicationProduct[] {
    if (!data) {
      return [];
    }

    return (
      data.products?.map((d) => ({
        id: d.id ?? 0,
        categoryId: d.categoryId ?? 0,
        categoryName: d.categoryName ?? "",
        name: d.name ?? "",
        image: d.image ?? "",
        plu: d.plu ?? "",
      })) || []
    );
  }
}
