import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DrawerID } from "../../../constants/drawer-constants";
interface DrawerState {
  id: DrawerID | null;
  anchor: "left" | "right" | "top" | "bottom";
  open?: boolean;
  data?: any | null;
}

const initialState: DrawerState = {
  id: null,
  anchor: "right",
  open: false,
  data: null,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<DrawerState>) => {
      state.id = action.payload.id;
      state.anchor = action.payload.anchor;
      state.data = action.payload.data;
      state.open = true;
    },
    closeDrawer: (state) => {
      state.open = false;
      state.data = null;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
