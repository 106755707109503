import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import Header from "../../components/layout/header";
import Back from "../../components/layout/back";
import {
  ActiveArea,
  ContentArea,
  HeaderArea,
  Main,
  Title,
  TitleArea,
  VerticalContent,
} from "../../styles/shared/page";
import ModalHandler from "../modals/modal-handler";
import { useBasket } from "../../hooks/useBasket";
import Toastr from "../../components/notification/toastr";
import { useErrorDisplay } from "../../hooks/useErrorDisplay";
import ConfirmModal from "../../components/notification/confirm-modal";
import DrawerHandler from "../drawer/drawer-handler";

interface LayoutProps {
  children: React.ReactNode;
}

const DepartmentLayout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user).user;
  const branch = useAppSelector((state) => state.branch).selectedBranch;
  const department = useAppSelector(
    (state) => state.department
  ).selectedDepartment;
  const basket = useBasket();
  useErrorDisplay();

  if (department == null) {
    return <></>;
  }

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <ConfirmModal></ConfirmModal>
      <DrawerHandler></DrawerHandler>
      <Header
        basket={basket}
        name={user?.name}
        team={user?.role}
        selectedBranch={branch}
      />
      <VerticalContent>
        <Back></Back>
        <HeaderArea>
          <TitleArea>
            <Title>{department.name}</Title>
          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>{children}</ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default DepartmentLayout;
