import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationDepartment } from "../../../models/organisation/application-department";
import DepartmentService from "../../../services/organisation/department-service";
import { ApplicationError } from "../../../models/errors/application-error";
import { ApplicationDepartmentCategoryList } from "../../../models/organisation/application-department-category-list";
import { ApplicationDepartmentList } from "../../../models/organisation/application-department-list";

interface DepartmentState {
  departments: ApplicationDepartmentList | null;
  departmentCategories: ApplicationDepartmentCategoryList | null;
  loading: boolean;
  selectedDepartment: ApplicationDepartment | null;
  error: string | null;
}

const initialState: DepartmentState = {
  departments: null,
  departmentCategories: null,
  loading: false,
  selectedDepartment: null,
  error: null,
};

export const fetchDepartments = createAsyncThunk<
  ApplicationDepartmentList,
  void,
  { state: RootState }
>("departments/fetch", async (_, { getState, rejectWithValue }) => {
  const departmentService = new DepartmentService();
  const state = getState();
  try {
    return await departmentService.get(0, -1, true);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});


export const fetchDepartmentCategories = createAsyncThunk<
  ApplicationDepartmentCategoryList | null,
  number,
  { state: RootState }
>(
  "products/department/categories/fetch",
  async (departmentId, { getState, rejectWithValue }) => {
    const departmentService = new DepartmentService();
    try {
      return await departmentService.getCategories(departmentId, 0, -1, true);
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);
const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    setSelectedDepartment: (
      state,
      action: PayloadAction<ApplicationDepartment | null>
    ) => {
      state.selectedDepartment = action.payload;
    },
    removeDepartment: (state, action: PayloadAction<number>) => {
      if (state.departments) {
        state.departments.departments =
          state.departments?.departments.filter(
            (department) => department.id !== action.payload
          ) || [];
      }
    },
    addDepartment: (state, action: PayloadAction<ApplicationDepartment>) => {
      state.departments?.departments.push(action.payload);
      state.departments!.count += 1;
      state.departments!.currentPosition += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDepartments.fulfilled,
        (state, action: PayloadAction<ApplicationDepartmentList>) => {
          state.departments = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchDepartments.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      )
      .addCase(
        fetchDepartmentCategories.fulfilled,
        (
          state,
          action: PayloadAction<ApplicationDepartmentCategoryList | null>
        ) => {
          if (action.payload) {
            state.departmentCategories = action.payload;
          }
        }
      );
  },
});

export const { setSelectedDepartment, removeDepartment, addDepartment } =
  departmentSlice.actions;

export default departmentSlice.reducer;
