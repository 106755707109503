import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { MainContent } from "../../styles/shared/drawer";
import TextInput from "../../components/controls/text-input";
import { ApplicationProductList } from "../../models/product/application-product-list";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showSuccess } from "../../components/notification/toastr-actions";
import ProductList from "../../components/controls/product-list";
import {
  setMapping,
  mapProduct,
} from "../../store/features/supplier/supplier-product-slice";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { searchSupplierProducts } from "../../store/features/supplier/supplier-product-slice";
import { resetSearchProducts } from "../../store/features/supplier/supplier-product-slice";
export default function MapProductDrawer() {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.supplierProducts);
  const { data } = useAppSelector((state) => state.drawer);
  const [searchText, setSearchText] = useState("");
  const [productLoading, setProductLoading] = useState(false);
  const loadingRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchText.length > 2 || searchText == "") {
      dispatch(resetSearchProducts());
      fetchSearchData();
    }
  }, [searchText]);

  const onMap = async (id: number, _: number, productName: string) => {
    await dispatch(mapProduct(id));
    dispatch(setMapping(id));
    dispatch(closeDrawer());
    dispatch(
      showSuccess(
        `Supplier product ${products.selectedUnmappedProduct?.name} mapped to ${productName}`
      )
    );
  };

  const fetchSearchData = async () => {
    await dispatch(
      searchSupplierProducts({
        supplierId: data.supplierId,
        searchText: searchText,
        fetchAll: false,
      })
    );
  };

  useInfiniteScroll({
    dataLength: products.searchProducts?.currentPosition ?? 0,
    totalCount: products.searchProducts?.count ?? 0,
    fetchMoreData: fetchSearchData,
    setLoading: setProductLoading,
    loading: productLoading,
    loadingRowRef,
  });

  return (
    <>
      <MainContent>
        {products.selectedUnmappedProduct && (
          <Container>
            <TextInput
              id="txtSearchProducts"
              label="Find product"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            ></TextInput>

            <MappingProducts>
              <ProductList
                loadingRowRef={loadingRowRef}
                products={
                  products.searchProducts ?? ({} as ApplicationProductList)
                }
                selectedProductIds={[
                  products.selectedUnmappedProduct?.productId ?? -99,
                ]}
                categoryId={-99}
                onClick={onMap}
                columns={3}
              />
            </MappingProducts>
          </Container>
        )}
      </MainContent>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const MappingProducts = styled.div`
  display: flex;
  font-size: 0.8rem;
  overflow-y: auto;
  max-height: 70vh;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.application.product.background};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.application.scrollBar};
    border: 2px solid ${(props) => props.theme.application.scrollBar};
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    max-height: 80vh;
  }
`;
