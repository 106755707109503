import styled from "styled-components";
import { useModal } from "../../hooks/useModal";
import { closeModal } from "../../store/features/modal/modal-slice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ICON_ID } from "../../components/icons/google-icon";
import Button from "../../components/controls/button";
import { ModalAnimation } from "../../styles/shared/animation-styles";
export default function ModalHandler() {
  const { component: ModalContent, data } = useModal();
  const dispatch = useAppDispatch();

  if (!ModalContent) {
    return null;
  }

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Backdrop>
      <Modal>
        <ModalHeader>
          <Button
            onClick={handleClose}
            label=""
            variant="text"
            icon={ICON_ID.Close}
            id="btnCloseModal"
          ></Button>
        </ModalHeader>
        <ModalBody>
          <ModalContent {...data} />
        </ModalBody>
      </Modal>
    </Backdrop>
  );
}

const Backdrop = styled.div`
  background: ${(props) => props.theme.application.modal.backdrop};
  ${ModalAnimation}
`;

const Modal = styled.div`
  background: ${(props) => props.theme.application.modal.background};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.application.boxShadow};
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-y: auto;
  max-height: 100vh;
  margin: auto 5px;

  @media (min-width: 800px) {
    margin: auto;
    min-width: 30%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: end;
  height: 40px;
  button {
    span {
      margin-right: 0px;
      font-size: 1.5rem;
    }
  }
`;

const ModalBody = styled.div`
  padding: 0px 16px;
  flex-grow: 1;
  margin-bottom: 30px;
`;
