import { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchDepartmentCategories, fetchProducts, resetCompletedCategories, resetProducts, setCompletedCategories, setCurrentProduct, setSelectedDepartmentCategory } from '../../store/features/product/continous-product-slice';
import { ApplicationDepartmentCategory } from '../../models/organisation/application-department-category';
import styled from 'styled-components';
import { fetchByProductId } from '../../store/features/ordering/basket/basket-slice';
import { ApplicationBasketItem } from '../../models/order/basket/application-basket-item';
import ProductModal from './product-modal';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingSpinner from '../../components/controls/loading-spinner';
import { GoogleIcon, ICON_ID } from '../../components/icons/google-icon';
import { showError } from '../../components/notification/toastr-actions';


interface ContinousModalProps {
    departmentId: number,
    branchId: number,
}

export default function ContinousProductModal({ departmentId, branchId }: ContinousModalProps) {
    const continousProduct = useAppSelector(state => state.continousProduct);
    const basket = useAppSelector(state => state.basket);
    const dispatch = useAppDispatch();
    const [progressValue, setProgressValue] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (departmentId) {
            dispatch(fetchDepartmentCategories(departmentId))
        }

    }, [departmentId])

    useEffect(() => {
        resetSelections(true);
    }, [basket.basket?.id])


    const handleDepartmentCategoryClick = (departmentCategory: ApplicationDepartmentCategory) => {
        if (!continousProduct?.completedCategories.find(x => x.id == departmentCategory.id)) {
            resetSelections(false);
            dispatch(setSelectedDepartmentCategory(departmentCategory))
            dispatch(fetchProducts(departmentCategory.id));
        }
    }

    const nextProduct = () => {
        if (continousProduct.currentProduct && continousProduct.products?.products) {
            var currentIndex = continousProduct.products.products.indexOf(continousProduct.currentProduct);

            if (currentIndex == continousProduct.products.count - 1) {
                dispatch(setCompletedCategories(continousProduct.selectedDepartmentCategory));
                resetSelections(false);
                return;
            }

            var nextIndex = currentIndex + 1;

            // we want to load the next set of products early, when there is 3 left.
            if (nextIndex == continousProduct.products.products.length - 3 && continousProduct?.selectedDepartmentCategory) {
                dispatch(fetchProducts(continousProduct.selectedDepartmentCategory.id))
            }

            if (nextIndex < continousProduct.products?.products.length) {
                var nextProduct = continousProduct.products.products[nextIndex];
                if (nextProduct) {
                    dispatch(setCurrentProduct(nextProduct))
                }

                const currentProductIndex = (nextIndex) + 1;
                const totalProducts = continousProduct?.products?.count ?? 0;
                setProgressValue((currentProductIndex / totalProducts) * 100);
            }
        }
    }


    // skips products that are in basket already.
    useEffect(() => {
        if (basket.basket && continousProduct.currentProduct) {
            setLoading(true);
            dispatch(fetchByProductId({ basketId: basket.basket.id, productId: continousProduct.currentProduct.id }))
                .then((response) => {
                    var basketItem = response.payload as ApplicationBasketItem;
                    if (basketItem.id > 0) {
                        nextProduct();
                    }
                }).finally(() => {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }
                    const id = setTimeout(() => {
                        setLoading(false)
                    }, 250);
                    setTimeoutId(id);
                })
        }
    }, [continousProduct.currentProduct])

    useEffect(() => {
        if (continousProduct.products?.products.length == 0) {
            dispatch(showError("No products are setup for this category"))
        }
    }, [continousProduct.products])


    const resetSelections = (resetCompleted: boolean) => {
        dispatch(setCurrentProduct(null));
        dispatch(resetProducts());
        dispatch(setSelectedDepartmentCategory(null));

        if (resetCompleted) {
            dispatch(resetCompletedCategories())
        }
    }

    return (
        <>
            {!continousProduct.selectedDepartmentCategory || !continousProduct.currentProduct ? (
                <>
                    <Title>Select Category</Title>
                    <DepartmentCategorySelection>
                        {continousProduct.departmentCategories?.departmentCategories.filter(x => x.active).map(x => {
                            const completed = continousProduct.completedCategories.find(cc => cc.id == x.id) != null;
                            return (<DepartmentCategory
                                completed={completed}
                                key={`departmentCategory_${x.id}`}
                                onClick={() => handleDepartmentCategoryClick(x)}>
                                {x.name}
                                <DepartmentCategoryDescription>
                                    {x.description}
                                </DepartmentCategoryDescription>
                                {completed && (
                                    <div>
                                        <GoogleIcon isContrast id={ICON_ID.CheckCircle}></GoogleIcon><span>Complete</span>
                                    </div>
                                )}
                            </DepartmentCategory>
                            )
                        })}
                    </DepartmentCategorySelection>
                </>
            ) : (
                <>
                    <ModalHeader>
                        <span>
                            {((continousProduct?.products?.products.indexOf(continousProduct.currentProduct) ?? 0) + 1)} / {continousProduct.products?.count ?? 0}
                        </span>
                        <LinearProgress variant="determinate" value={progressValue} sx={{ mt: 2 }} />
                    </ModalHeader>

                    {loading ? <LoadingSpinner ></LoadingSpinner> : (
                        <ProductModal skipText='Skip' branchId={branchId} productId={continousProduct.currentProduct.id} basketCheck={false} addToBasketCallback={() => nextProduct()}></ProductModal >
                    )}
                </>
            )
            }
        </>

    )
}

const Title = styled.div`
    font-size:1.3rem;
    font-weight:500;
    margin-bottom:15px;
    padding-bottom:10px;
    width:100%;
    text-align:center;
    border-bottom: 1px solid ${(props) => props.theme.application.scheme.primary};
`

const DepartmentCategorySelection = styled.div`
    display:flex;
    justify-content: space-evenly;    
    font-weight:600;
    font-size:1.2rem;
    flex-wrap: wrap;
    border-bottom: 1px solid gainsboro;
    margin-bottom:20px;
`
interface DepartmentCategoryProps {
    completed: boolean
}
const DepartmentCategory = styled.div<DepartmentCategoryProps>`         
    font-size:1.1rem;
    width: 100%;
    height:40px;
    border: 1px solid ${(props) => props.theme.application.border};
    border-bottom:none;
    padding:10px 15px;
    font-weight:600;
    background: ${(props) => (props.completed ? props.theme.application.scheme.success : "")};
    cursor:pointer;   

    color:  ${(props) => (props.completed ? props.theme.application.scheme.contrast : props.theme.application.scheme.neutral)};      
    opacity: ${(props) => (props.completed ? 0.6 : 1)};

    &:hover{
            opacity:0.9;       
            background:  ${(props) => props.theme.application.hover};
        }
         
    div{
        font-size:0.9rem;
        font-weight:400;
        span{
        margin-right:5px;
        margin-left:0px;
        }
    }
`
const DepartmentCategoryDescription = styled.div`
    margin-top:3px;
    font-size:1rem;
    padding-right:10px;
    font-weight:400;
`
const ModalHeader = styled.div`
    text-align:center;
    margin-bottom:24px;
    span {
        font-size:1rem;
        font-style: italic;    
    }
`