import React from "react";
import { GoogleIcon, ICON_SIZES } from "../icons/google-icon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { SxProps } from "@mui/material";
interface TextInputProps {
  id: string;
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  error?: string | null;
  iconID?: number;
  type?: string;
  disabled?: boolean;
  multiline?: boolean;
  maxRows?: number;
  autoFocus?: boolean;
  sx?: SxProps;
  rows?: number;
}

export default function TextInput(properties: TextInputProps) {
  return (
    <TextField
      id={properties.id}
      label={properties.label}
      disabled={properties.disabled ?? false}
      variant="outlined"
      type={properties.type ?? "text"}
      onChange={properties.onChange}
      onBlur={properties.onBlur}
      margin="normal"
      fullWidth
      error={!!properties.error}
      helperText={properties.error}
      autoFocus={properties.autoFocus ?? false}
      value={properties.value}
      multiline={properties.multiline ?? false}
      rows={properties.rows}
      maxRows={
        properties.multiline ? properties.maxRows ?? Infinity : undefined
      }
      sx={properties.sx} // Pass sx dynamically if provided
      InputProps={{
        inputMode: properties.type == "number" ? "numeric" : "text",
        startAdornment:
          properties.iconID !== undefined ? (
            <InputAdornment position="start">
              <GoogleIcon size={ICON_SIZES.Medium} id={properties.iconID} />
            </InputAdornment>
          ) : null,
      }}
    />
  );
}
