import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationCategory } from "../../../models/product/application-category";
import { ApplicationCategoryList } from "../../../models/product/application-category-list";
import { ApplicationError } from "../../../models/errors/application-error";
import ProductCategoryService from "../../../services/product/product-category-service";
import { ApplicationDepartment } from "../../../models/organisation/application-department";
export const fetchCategories = createAsyncThunk<
  ApplicationCategoryList | null,
  { departmentId: number; search: boolean, fetchAll?: boolean },
  { state: RootState }
>(
  "categories/get",
  async ({ departmentId, search, fetchAll }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const categoryService = new ProductCategoryService();
    try {
      var currentPosition: number =
        state.productCategory?.categories?.currentPosition ?? 0;
      var fetchNext: number = 15;
      var count: number = state.productCategory.categories?.count ?? -1;

      if (search || fetchAll) {
        currentPosition = 0;
        count = -1;
        dispatch(resetCategory());
      }

      if (currentPosition !== count) {
        return await categoryService.getCategoriesByDepartment(
          departmentId,
          currentPosition,
          fetchNext,
          state.productCategory.searchText,
          fetchAll
        );
      }
      return null;
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);

export const fetchCategory = createAsyncThunk<
  ApplicationCategory | null,
  number,
  { state: RootState }
>("categories/getSingle", async (categoryId, { rejectWithValue }) => {
  const categoryService = new ProductCategoryService();

  try {
    return await categoryService.getCategory(categoryId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const insertCategory = createAsyncThunk<
  ApplicationCategory | null,
  ApplicationCategory,
  { state: RootState }
>("categories/insert", async (category, { rejectWithValue }) => {
  const categoryService = new ProductCategoryService();

  try {
    return await categoryService.insertCategory(category);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const updateCategory = createAsyncThunk<
  ApplicationCategory | null,
  ApplicationCategory,
  { state: RootState }
>("categories/update", async (category, { rejectWithValue }) => {
  const categoryService = new ProductCategoryService();

  try {
    return await categoryService.updateCategory(category);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const deleteCategory = createAsyncThunk<
  void,
  number,
  { state: RootState }
>("departments/deleteCategory", async (categoryId, { rejectWithValue }) => {
  const categoryService = new ProductCategoryService();
  try {
    await categoryService.deleteCategory(categoryId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

interface CategoryState {
  categories: ApplicationCategoryList | null;
  selectedCategory: ApplicationCategory | null;
  selectedDepartment: ApplicationDepartment | null;
  searchText: string;
  loading: boolean;
}

const initialState: CategoryState = {
  categories: null,
  selectedCategory: null,
  selectedDepartment: null,
  searchText: "",
  loading: false,
};

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {
    resetSelectedCategory: (state) => {
      state.selectedCategory = initialState.selectedCategory;
    },
    resetSelectedDepartment: (state) => {
      state.selectedDepartment = initialState.selectedDepartment;
    },
    resetCategory: (state) => {
      state.categories = initialState.categories;
    },
    setSelectedDepartment: (
      state,
      action: PayloadAction<ApplicationDepartment>
    ) => {
      state.selectedDepartment = action.payload;
    },
    setSelectedCategory: (
      state,
      action: PayloadAction<ApplicationCategory>
    ) => {
      state.selectedCategory = action.payload;
    },
    setLoadingCategory(state, action: PayloadAction<boolean>) {
      if (state.categories) {
        state.categories.loading = action.payload;
      }
    },

    removeCategory: (state, action: PayloadAction<number>) => {
      if (state.categories?.categories) {
        state.categories.categories = state.categories.categories.filter(
          (category) => category.id !== action.payload
        );
        state.categories.count -= 1;
      }
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<ApplicationCategoryList | null>) => {
          if (action.payload) {
            if (state.categories?.categories) {
              state.categories.categories = state.categories.categories.concat(
                action.payload.categories
              );
              state.categories.currentPosition = action.payload.currentPosition;
              state.categories.count = action.payload.count;
            } else {
              state.categories = action.payload;
            }

            state.loading = false;
          }
        }
      )
      .addCase(
        insertCategory.fulfilled,
        (state, action: PayloadAction<ApplicationCategory | null>) => {
          if (action.payload && state.categories?.categories) {
            state.categories.categories.unshift(action.payload);
            state.categories.count += 1;
            state.categories.currentPosition += 1;
          }
        }
      )
      .addCase(
        fetchCategory.fulfilled,
        (state, action: PayloadAction<ApplicationCategory | null>) => {
          if (action.payload) {
            state.selectedCategory = action.payload;
          }
        }
      )
      .addCase(
        updateCategory.fulfilled,
        (state, action: PayloadAction<ApplicationCategory | null>) => {
          if (action.payload && state.categories?.categories) {
            state.categories.categories = state.categories.categories.map(
              (category) =>
                category.id === action.payload!.id
                  ? (action.payload as ApplicationCategory)
                  : category
            );
          }
        }
      );
  },
});

export const {
  resetSelectedCategory,
  resetSelectedDepartment,
  setSelectedDepartment,
  setLoadingCategory,
  removeCategory,
  resetCategory,
  setSelectedCategory,
  setSearch,
} = productCategorySlice.actions;
export default productCategorySlice.reducer;
