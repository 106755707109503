import IAuthenticationService from "./authentication-service-interface";
import { ApplicationUser } from "../../models/authentication/application-user";
import { ApplicationRole } from "../../models/authentication/application-roles";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationBranch } from "../../models/organisation/application-branch";
import client from "../../external/open-api";
import { components } from "../../external/schema/order-link-api-schema";

export default class AuthenticationService implements IAuthenticationService {
  async logout(): Promise<void> {
    const { data, error } = await client.POST(
      "/v{version}/api/authentication/logout",
      {
        params: {
          path: { version: "1" },
        },
      }
    );

    if (error || !data.success) {
      throw new ApplicationError("Logout failed due to an api issues");
    }
  }

  async login(email: string, password: string): Promise<ApplicationUser> {
    const { data, error } = await client.POST(
      "/v{version}/api/authentication/login",
      {
        body: {
          email: email,
          password: password,
        },
        params: {
          path: { version: "1" },
        },
      }
    );

    if (error || !data.success) {
      throw new ApplicationError(
        "Authentication failed, please check your credentials"
      );
    }

    return this.fetchUserInfo(data?.data ?? {});
  }

  async sendReset(
    email: string,
    token?: string | null,
    newPassword?: string | null
  ): Promise<void> {
    const { data, error } = await client.POST(
      "/v{version}/api/authentication/reset",
      {
        body: {
          email: email,
          token: token,
          newPassword: newPassword,
        },
        params: {
          path: { version: "1" },
        },
      }
    );

    if (error || !data.success) {
      throw new ApplicationError(
        "The link has expired or the email you provided is incorrect."
      );
    }
  }

  async getUserInfo(): Promise<ApplicationUser> {
    const { data, error } = await client.GET(
      "/v{version}/api/authentication/user",
      {
        params: {
          path: { version: "1" },
        },
      }
    );

    if (error || !data.success) {
      throw new ApplicationError("User is not logged in");
    }

    return this.fetchUserInfo(data?.data ?? {});
  }

  private fetchUserInfo(
    response: components["schemas"]["UserDTO"]
  ): ApplicationUser {
    const branches: ApplicationBranch[] =
      response?.branches?.map((x) => {
        return {
          id: x?.id ?? 0,
          name: x?.name ?? "",
        };
      }) ?? [];

    return {
      username: response?.username ?? "",
      name: response?.name ?? "",
      branches: branches,
      role: mapStringToEnum(ApplicationRole, response?.role ?? ""),
    };
  }
}
