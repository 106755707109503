import React from "react";
import { GoogleIcon, ICON_ID, ICON_SIZES } from "../icons/google-icon";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "./checkbox";
import { FormHelperText } from "@mui/material";

interface DropdownProps extends SelectInputProps {
  options: { value: string; label: string }[];
  label: string;
  id: string;
  errorMessage?: string | null;
  variant: "outlined" | "filled" | "standard";
}

export default function Dropdown({
  id,
  options,
  variant,
  label,
  errorMessage,
  ...props
}: DropdownProps) {
  return (
    <FormControl
      variant={variant}
      fullWidth
      error={!!errorMessage}
      sx={{ mt: "16px", mb: "8px" }}
    >
      <InputLabel id={id + "_label"}>{label}</InputLabel>
      <Select label={label} labelId={id + "_label"} id={id} {...props}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple ? (
              <Checkbox
                label=""
                checked={(props.value as string[]).indexOf(option.value) > -1}
              />
            ) : (
              ""
            )}
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText> // Display the error message
      )}
    </FormControl>
  );
}
