import { useEffect, useState } from "react";
import { getLoggedInUser } from "../store/features/authentication/user-slice";
import { RootState } from "../store/store";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import { setSelectedBranch } from "../store/features/organisation/branch-slice";

const useAuth = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state: RootState) => state.user);
    const branch = useAppSelector((state: RootState) => state.branch);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        // Fetch the logged-in user and set initialized to true once done
        dispatch(getLoggedInUser()).finally(() => setInitialized(true));
    }, []);

    useEffect(() => {
        if (branch.selectedBranch == null && user.user != null) {
            const persistedBranchId = sessionStorage.getItem("branch")
            var selectedBranch = user.user.branches[0];
            if (persistedBranchId) {
                const persistedBranch = user.user.branches.find(x => x.id == parseInt(persistedBranchId));
                if (persistedBranch) {
                    selectedBranch = persistedBranch;
                }
            }
            if (selectedBranch) {
                dispatch(setSelectedBranch(selectedBranch));
            }
        }
    }, [user, branch.selectedBranch])

    if (user.loading) {
        return { initialized, loading: true, authenticated: false };
    }

    if (!user.authenticated) {
        return { initialized, loading: false, authenticated: false };
    }

    return { initialized, loading: false, authenticated: true, role: user.user?.role };
};

export default useAuth;
