import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConfirmState {
  show: boolean;
  title: string;
  message: string;
  data?: ConfirmModalActions | null;
}

interface ConfirmModalActions {
  onConfirm: () => void;
  onCancel: () => void;
}

const initialState: ConfirmState = {
  show: false,
  title: "",
  message: "",
  data: null,
};

const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    openConfirm: (state, action: PayloadAction<ConfirmState>) => {
      state.show = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
    closeConfirm: (state) => {
      state.show = false;
      state.data = null;
      state.title = initialState.title;
      state.message = initialState.message;
      state.data = initialState.data;
    },
  },
});

export const { openConfirm, closeConfirm } = confirmSlice.actions;

export default confirmSlice.reducer;
