export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
});

export function toTimeSpanString(timeSpan: {
  hours: number;
  minutes: number;
  seconds: number;
}): string {
  const pad = (num: number) => String(num).padStart(2, "0");

  const hours = pad(timeSpan.hours);
  const minutes = pad(timeSpan.minutes);
  const seconds = pad(timeSpan.seconds);

  return `${hours}:${minutes}:${seconds}`;
}

export function getTicks(timeSpan: string) {
  const [hours, minutes, seconds] = timeSpan.split(":").map(Number);

  // Create a new Date object with the current date
  const currentDate = new Date();
  currentDate.setHours(hours ?? 0, minutes, seconds, 0); // 0 milliseconds
  var dStart = new Date(1970, 1, 1);

  return (currentDate.getTime() - dStart.getTime()) * 10000;
}
