import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen/instance/Cloudinary";
import React from "react";
import styled from "styled-components";

interface ImageProps {
  image: string;
}

export default function Image({ image }: ImageProps) {
  if (!image) {
    return <></>;
  }

  return (
    <ImageContainer>
      <img src={image} />
    </ImageContainer>
  );
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;
