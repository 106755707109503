import { RootState } from "../store/store";
import { DrawerComponents } from "../constants/drawer-constants";
import { useMemo } from "react";
import { useAppSelector } from "./useAppSelector";

interface DrawerContent {
  component: React.ComponentType<any> | null;
  data: any | null;
}

export const useDrawer = (): DrawerContent => {
  const drawer = useAppSelector((state: RootState) => state.drawer);

  const component = useMemo(() => {
    if (!drawer.id) {
      return null;
    }

    return DrawerComponents[drawer.id];
  }, [drawer]);

  if (!drawer.id) {
    return {
      component: null,
      data: null,
    };
  }

  return {
    component,
    data: drawer.data,
  };
};
