import { useState, useCallback } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import { LinearProgress, Alert, AlertTitle } from "@mui/material";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { storeProcessedRecords } from "../../store/features/supplier/supplier-product-upload-slice";
import FileUploader from "../../components/controls/file-uploader";
import Papa from "papaparse";
import { closeModal } from "../../store/features/modal/modal-slice";
import { ApplicationProductUpload } from "../../models/supplier/application-product-upload";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";

export default function UploadSupplierProductModal() {
  const dispatch = useAppDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [records, setRecords] = useState<ApplicationProductUpload[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const handleFileSelected = useCallback((files: FileList) => {
    const file = files[0];

    if (!file) {
      setErrors(["No file selected. Please upload a valid file."]);
      return;
    }

    setErrors([]);
    setProgress(0);
    setRecords([]);
    setTotalRecords(0);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.trim().toLowerCase(),
      complete: (results) => {
        const data = results.data as ApplicationProductUpload[];
        const headers =
          results.meta.fields?.map((header) => header.trim().toLowerCase()) ||
          [];

        // Check for required headers
        const requiredHeaders = [
          "name",
          "reference",
          "price",
          "size",
          "sizeunit",
        ];
        const missingHeaders = requiredHeaders.filter(
          (header) => !headers.includes(header)
        );

        if (missingHeaders.length > 0) {
          setErrors([
            `The file is not compatible. Missing headers: ${missingHeaders.join(
              ", "
            )}.`,
          ]);
          return;
        }

        if (data.length === 0) {
          setErrors(["The file is empty. Please upload a valid file."]);
          return;
        }

        const sanitizedData = data.map((record) => ({
          ...record,
          name: capitalizeName(record.name),
        })) as ApplicationProductUpload[];

        const validationErrors = validateRecords(sanitizedData);
        if (validationErrors.length > 0) {
          setErrors(validationErrors);
          return;
        }

        const uniqueRecords = removeDuplicates(sanitizedData, "name");
        setRecords(uniqueRecords);
        setTotalRecords(uniqueRecords.length);
        setProgress(0);
        setErrors([]);
      },
      error: (error) => {
        console.error("Error parsing file:", error);
        setErrors([
          "There was an error processing the file. Please try again with a valid file.",
        ]);
      },
    });
  }, []);

  const capitalizeName = (value: string | undefined): string => {
    if (!value) return "";
    return value
      .toString()
      .split(" ")
      .map(
        (word: string) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  };

  const removeDuplicates = <K extends keyof ApplicationProductUpload>(
    data: ApplicationProductUpload[],
    key: K
  ) => {
    const uniqueMap = new Map<string, ApplicationProductUpload>();

    data.forEach((item) => {
      const keyValue = String(item[key]).trim().toLowerCase();
      if (!uniqueMap.has(keyValue)) {
        uniqueMap.set(keyValue, item);
      }
    });

    return Array.from(uniqueMap.values());
  };
  const validateRecords = (records: ApplicationProductUpload[]) => {
    let errors: string[] = [];
    records.forEach((record, index) => {
      const lineNumber = index + 1;

      if (!record.name || record.name.trim() === "") {
        errors.push(`Error on line ${lineNumber}: Missing name.`);
      }

      // Validate 'price'
      if (
        !record.price || // Ensure price is not undefined or null
        isNaN(Number(record.price)) || // Ensure price is a valid number
        Number(record.price) <= 0 // Ensure price is greater than 0
      ) {
        errors.push(`Error on line ${lineNumber}: Price is invalid.`);
      }

      // Validate 'size'
      if (!record.size || record.size == 0) {
        errors.push(`Error on line ${lineNumber}: Size is missing.`);
      }

      // Validate 'size unit'
      if (
        !record.sizeunit ||
        record.sizeunit == ApplicationSizeUnit.Unknown ||
        !Object.values(ApplicationSizeUnit).includes(record.sizeunit)
      ) {
        errors.push(
          `Error on line ${lineNumber}: ${record.sizeunit} as a size unit is missing or not known`
        );
      }
    });

    if (errors.length > 10) {
      const displayedErrors = errors.slice(0, 10);
      displayedErrors.push(`And ${errors.length - 10} more errors...`);
      return displayedErrors;
    }

    return errors;
  };

  const handleUpload = async () => {
    if (records.length === 0) return;

    setIsProcessing(true);
    dispatch(storeProcessedRecords(records));

    let processedCount = 0;
    const interval = setInterval(() => {
      processedCount += 1;
      setProgress((processedCount / totalRecords) * 100);

      if (processedCount === totalRecords) {
        clearInterval(interval);
        setIsProcessing(false);
        dispatch(closeModal());
      }
    }, 100);
  };

  return (
    <Container>
      <Title>Upload file</Title>
      <FileUploader
        buttonText="Browse file"
        placeholder="No file selected"
        acceptedFileTypes=".csv,.txt"
        onFileSelected={handleFileSelected}
      />

      {errors.length > 0 && (
        <ErrorContainer>
          <Alert severity="warning">
            <AlertTitle>Validation Errors</AlertTitle>
            {errors.map((error, idx) => (
              <div key={idx}>{error}</div>
            ))}
            <ResolutionMessage>
              Please address the issues above and upload the file again.
            </ResolutionMessage>
          </Alert>
        </ErrorContainer>
      )}

      {isProcessing && (
        <ProgressContainer>
          <LinearProgress variant="determinate" value={progress} />
          <ProgressText>
            {Math.round(progress)}% ({records.length} records)
          </ProgressText>
        </ProgressContainer>
      )}

      <ButtonContainer>
        <Button
          id="btnCreate"
          label="Upload"
          variant="contained"
          onClick={handleUpload}
          disabled={isProcessing || records.length === 0}
        />
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
`;

const ProgressText = styled.div`
  margin-top: 8px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`;

const ResolutionMessage = styled.div`
  margin-top: 10px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.warning.dark};
`;
