import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  matchValidator,
  minValueValidator,
  requiredValidator,
} from "../../helpers/validators";
import { showSuccess } from "../../components/notification/toastr-actions";
import { closeModal } from "../../store/features/modal/modal-slice";
import {
  FeatureCard,
  FeatureCardColumn,
  FeatureCardRow,
  FeatureCardTitle,
} from "../../styles/shared/card";
import TextInput from "../../components/controls/text-input";
import Checkbox from "../../components/controls/checkbox";
import Button from "../../components/controls/button";
import styled from "styled-components";
import { ApplicationSupplierProductDetail } from "../../models/supplier/application-supplier-product-detail";
import { ApplicationProductDetail } from "../../models/product/application-product-detail";
import { fetchProduct } from "../../store/features/product/product-slice";
import {
  fetchSupplierProduct,
  insertSupplierProduct,
  resetSearchProducts,
  resetSizeUnitUpdatedProducts,
  searchSupplierProducts,
  updateProductSizeUnit,
  updateSupplierProduct,
} from "../../store/features/supplier/supplier-product-slice";
import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationProduct } from "../../models/product/application-product";
import Alert from "@mui/material/Alert";
import {
  SmallTextContainer,
  UnderlinedTextContainer,
} from "../../styles/shared/table";
import SizeInput from "../../components/controls/size-input";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
import SearchDropdown from "../../components/controls/search-dropdown";
import {
  ProductContainer,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../styles/shared/product";
import CurrencyInput from "../../components/controls/currency-input";
import { currencyFormatter } from "../../helpers/formatters";
import Image from "../../components/controls/image";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";

interface SupplierProductModalProps {
  id: number;
}

interface Validation {
  name: string | null;
  size: string | null;
  sizeUnit: string | null;
  price: string | null;
}

export default function SupplierProductDrawer({
  id,
}: SupplierProductModalProps) {
  const dispatch = useAppDispatch();
  const supplierProductState = useAppSelector(
    (state) => state.supplierProducts
  );
  const supplierState = useAppSelector((state) => state.supplier);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [validation, setValidation] = useState<Validation>({
    name: null,
    size: null,
    sizeUnit: null,
    price: null,
  });

  const [product, setProduct] = useState<ApplicationSupplierProductDetail>({
    id: 0,
    reference: "",
    supplierId: supplierState.selectedSupplier?.id ?? 0,
    name: "",
    productId: null,
    description: null,
    image: "",
    size: 0,
    sizeUnit: ApplicationSizeUnit.KG,
    plu: "",
    updated: new Date(),
    active: true,
    price: null,
    created: new Date(),
  });

  const [mappedProduct, setMappedProduct] = useState<ApplicationProductDetail>({
    id: 0,
    name: "",
    description: "",
    categoryId: 0,
    image: "",
    plu: "",
    active: true,
    information: {},
    suggestedQuantity: 0,
    branches: [],
  });

  const loadProductMapping = (productId: number) => {
    if (productId > 0) {
      dispatch(fetchProduct(productId)).then((response) => {
        var product = response.payload as ApplicationProductDetail;
        setMappedProduct(product);
      });
    }
  };

  useEffect(() => {
    if (id)
      dispatch(fetchSupplierProduct(id)).then((response) => {
        const product = response.payload as ApplicationSupplierProductDetail;
        if (product) {
          setProduct(product);
          loadProductMapping(product?.productId ?? 0);
        }
      });

    return () => {
      dispatch(resetSizeUnitUpdatedProducts());
    };
  }, [id]);

  useEffect(() => {
    if (product.price && product.sizeUnit && product.size) {
      setUnitPrice(product.price / product.size);
    }
  }, [product.size, product.sizeUnit, product.price]);

  const onChange = <K extends keyof ApplicationSupplierProductDetail>(
    value: ApplicationSupplierProductDetail[K],
    property: K,
    validation?: (input: ApplicationSupplierProductDetail[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;

    setProduct((prevState) => ({
      ...prevState,
      [property]: value,
    }));

    setValidation((prevState) => ({
      ...prevState,
      [property]: errorMessage,
    }));
  };

  const fetchProducts = async (query: string): Promise<void> => {
    if (!supplierState.selectedSupplier) {
      throw new ApplicationError("No selected supplier");
    }

    await dispatch(
      searchSupplierProducts({
        supplierId: supplierState.selectedSupplier?.id,
        searchText: query,
        fetchAll: true,
      })
    );
  };

  const isProductEqual = (
    option: ApplicationProduct,
    value: ApplicationProduct
  ) => option.id == value.id;

  const renderProductOption = (
    props: React.HTMLProps<HTMLLIElement>,
    option: ApplicationProduct
  ) => (
    <StyledOption {...props}>
      <OptionImage src={option.image} alt={option.name} />
      <OptionText>
        <OptionName>{option.name}</OptionName>
        <OptionSize>Plu: {option.plu}</OptionSize>
      </OptionText>
    </StyledOption>
  );

  const onAutocompleteChange = (value: ApplicationProduct | null) => {
    onChange(value?.id ?? 0, "productId");
    loadProductMapping(value?.id ?? 0);
  };

  const updateProduct = async () => {
    if (!validateAndCheckErrors()) {
      return;
    }

    var notificationMessage = `${product.name} `;
    if (product.id > 0) {
      await dispatch(updateSupplierProduct(product));
      notificationMessage += "updated";
    } else {
      await dispatch(insertSupplierProduct(product));
      notificationMessage += "created";
    }

    dispatch(showSuccess(notificationMessage));
    dispatch(closeDrawer());
  };

  const validateAndCheckErrors = () => {
    const validation = {
      name: requiredValidator(product.name),
      size: requiredValidator(product.size),
      price: minValueValidator(product.price),
      sizeUnit: matchValidator(
        product.sizeUnit,
        mappedProduct?.supplierSizeUnit,
        true,
        `The size unit must be ${mappedProduct.supplierSizeUnit}`
      ),
    };

    setValidation(validation);

    const hasErrors = Object.values(validation).some((error) => error !== null);

    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };

  const updateSupplierSizeUnits = () => {
    const newSizeUnit = product.sizeUnit;
    const productId = product.productId;

    if (productId) {
      dispatch(updateProductSizeUnit({ productId, newSizeUnit })).then(() => {
        loadProductMapping(productId);
      });
      validation.sizeUnit = null;
      setValidation(validation);
    }
  };

  return (
    <Container>
      {validation.sizeUnit && (
        <Alert
          sx={{
            marginBottom: "10px",
          }}
          variant="outlined"
          severity="error"
        >
          The mapped product '{mappedProduct.name}' is purchased in units of '
          {mappedProduct.supplierSizeUnit}' from other suppliers. To
          standardise, update all other suppliers to use '{product.sizeUnit}'.
          <UnderlinedTextContainer onClick={updateSupplierSizeUnits}>
            Click here to automatically update other suppliers
          </UnderlinedTextContainer>
        </Alert>
      )}
      {supplierProductState.sizeUnitUpdatedProducts && (
        <Alert
          sx={{
            marginBottom: "10px",
          }}
          variant="outlined"
          severity="success"
          onClose={() => dispatch(resetSizeUnitUpdatedProducts())}
        >
          The following suppliers product have been updated to use the new size
          unit '{product.sizeUnit}'.
          {supplierProductState.sizeUnitUpdatedProducts.map((x) => (
            <div>
              <strong>{x.supplierName}</strong> - {x.name}{" "}
            </div>
          ))}
        </Alert>
      )}

      <Body>
        <Active>
          <Checkbox
            id="cbActive"
            checked={product.active}
            onChange={(event) => onChange(event.target.checked, "active")}
            label="Active"
          ></Checkbox>
        </Active>
        <FeatureCard>
          <FeatureCardTitle>Product information</FeatureCardTitle>
          <FeatureCardRow>
            <FeatureCardColumn>
              <TextInput
                id="txtProductName"
                value={product.name}
                label="Name"
                onChange={(event) =>
                  onChange(event.target.value, "name", (input) =>
                    requiredValidator(input)
                  )
                }
                error={validation.name}
              ></TextInput>
            </FeatureCardColumn>
            <FeatureCardColumn>
              <SizeInput
                id="supplier-product-size"
                label="Size"
                valueNumber={product.size}
                valueUnit={product.sizeUnit}
                onChange={(newValue: {
                  number: number;
                  unit: ApplicationSizeUnit;
                }) => {
                  onChange(newValue.number, "size", (input) =>
                    requiredValidator(input)
                  );
                  onChange(newValue.unit, "sizeUnit", (input) =>
                    matchValidator(
                      input,
                      mappedProduct?.supplierSizeUnit,
                      true,
                      `The size unit must be ${mappedProduct.supplierSizeUnit}`
                    )
                  );
                  dispatch(resetSizeUnitUpdatedProducts());
                }}
                error={validation.size ?? validation.sizeUnit}
              />
            </FeatureCardColumn>
          </FeatureCardRow>
          <FeatureCardRow>
            <FeatureCardColumn>
              <TextInput
                id="txtReference"
                value={product.reference}
                label="Supplier reference"
                onChange={(event) => onChange(event.target.value, "reference")}
              ></TextInput>
            </FeatureCardColumn>
          </FeatureCardRow>
        </FeatureCard>
        <FeatureCard>
          <FeatureCardTitle>Mapping</FeatureCardTitle>
          <FeatureCardRow>
            <FeatureCardColumn>
              <SearchDropdown<ApplicationProduct>
                fetchOptions={fetchProducts}
                clearOptions={() => dispatch(resetSearchProducts())}
                options={supplierProductState.searchProducts?.products ?? []}
                label="Search products"
                onChange={onAutocompleteChange}
                isOptionEqualToValue={isProductEqual}
                renderOption={renderProductOption}
              />
            </FeatureCardColumn>
          </FeatureCardRow>

          <FeatureCardRow>
            <FeatureCardColumn>
              {mappedProduct.id > 0 && (
                <MappedProduct>
                  <ProductContainer>
                    <ProductImage>
                      <Image image={mappedProduct.image}></Image>
                    </ProductImage>
                    <ProductNameContainer>
                      <ProductTitle>{mappedProduct.name}</ProductTitle>
                      <div>{mappedProduct.description} </div>
                      <SmallTextContainer>
                        Plu: {mappedProduct.plu}
                      </SmallTextContainer>
                    </ProductNameContainer>
                  </ProductContainer>
                </MappedProduct>
              )}
            </FeatureCardColumn>
          </FeatureCardRow>
        </FeatureCard>

        <FeatureCard>
          <FeatureCardTitle>Price</FeatureCardTitle>
          <FeatureCardRow>
            <FeatureCardColumn>
              <CurrencyInput
                value={product.price}
                onChange={(event) =>
                  onChange(parseFloat(event.target.value), "price", (input) =>
                    minValueValidator(input)
                  )
                }
                error={!!validation.price}
                helperText={validation.price}
              ></CurrencyInput>
              <Alert severity="info">
                <strong>{currencyFormatter.format(unitPrice)}</strong> per{" "}
                {product.sizeUnit}
              </Alert>
            </FeatureCardColumn>
          </FeatureCardRow>
        </FeatureCard>
      </Body>
      <ButtonContainer>
        <Button
          id="btnCreate"
          label={product.id == 0 ? "Add" : "Update"}
          variant="contained"
          onClick={updateProduct}
        ></Button>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1;
`;

const Body = styled.div`
  flex: 1;
`;
const Active = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
display:flex;
bottom:0;
margin-top:10px;
    justify-content: flex-end;

    button:first-of-type{
        margin-right:10px;
    }
}

`;

const StyledOption = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const OptionImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 4px;
`;

const OptionText = styled.div`
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
`;

const OptionName = styled.span`
  font-weight: bold;
  margin-bottom: 4px;
`;

const OptionSize = styled.span`
  color: #666;
`;

const MappedProduct = styled.div`
  margin-top: 20px;
`;
