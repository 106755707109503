import styled from "styled-components";

export const MainContent = styled.div`
  flex: 1;
  padding: 16px;
`;

export const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;
