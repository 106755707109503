import client from "../../external/open-api";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationApprovalStatus } from "../../models/order/application-approval-status";
import { ApplicationBasket } from "../../models/order/basket/application-basket";
import { ApplicationBasketItem } from "../../models/order/basket/application-basket-item";
import { ApplicationBasketItemList } from "../../models/order/basket/application-basket-item-list";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
import IBasketService from "./basket-service-interface";

export default class BasketService implements IBasketService {

    async getUserBasket(branchId: number): Promise<ApplicationBasket> {
        const { data, error } = await client.GET("/v{version}/api/basket/user/branch/{branchId}", {
            params: {
                path: { version: "1", branchId },
            },
        });

        if (!data?.data || !data?.success || error) {
            throw new ApplicationError("Failed to fetch user basket");
        }

        return {
            id: data.data?.id ?? 0,
            itemCount: data.data?.itemCount ?? 0
        };
    }

    async getBasketItems(basketId: number, currentPosition: number, fetchNext: number, searchText: string): Promise<ApplicationBasketItemList> {

        const { data, error } = await client.GET("/v{version}/api/basket/{basketId}/basketitems", {
            params: {
                path: { version: "1", basketId: basketId },  // Path parameter
                query: {
                    "Paging.CurrentPosition": currentPosition,
                    "Paging.FetchNext": fetchNext,
                    "Paging.FetchAll": false,
                    "SearchText": searchText
                },
            },
        });

        if (!data?.data || error || !data.success) {
            throw new ApplicationError("Failed to fetch user basket items");
        }

        const items = data.data.items?.map(d => ({
            id: d.id ?? 0,
            basketId: d.basketId ?? 0,
            productId: d.productId ?? 0,
            supplierId: d.supplierId ?? 0,
            productName: d.productName ?? "",
            productImage: d.productImage ?? "",
            productDescription: d.productDescription ?? "",
            size: d.size ?? 0,
            sizeUnit: mapStringToEnum(ApplicationSizeUnit, d.sizeUnit ?? ""),
            quantity: d.quantity ?? 0,
            notes: d.notes ?? "",
            currentStock: d.currentStock ?? 0,
            plu: d.plu ?? "",
            approvalStatus: mapStringToEnum(ApplicationApprovalStatus, d.approvalStatus ?? "")
        })) ?? [];

        return {
            items,
            count: data.data.count ?? 0,
            currentPosition: data.data?.currentPosition ?? 0,
        };

    }

    async getByProductId(basketId: number, productId: number): Promise<ApplicationBasketItem | null> {
        const { data, error } = await client.GET("/v{version}/api/basket/{basketId}/products/{productId}", {
            params: {
                path: { version: "1", basketId, productId },  // Path parameter             
            },
        });

        if (!data?.success || error) {
            throw new ApplicationError("Failed to fetch user basket items for this product");
        }

        const response = data.data;

        return {
            id: response?.id ?? 0,
            basketId: response?.basketId ?? 0,
            productId: response?.productId ?? 0,
            supplierId: response?.supplierId ?? 0,
            productName: response?.productName ?? "",
            productDescription: response?.productDescription ?? "",
            productImage: response?.productImage ?? "",
            size: response?.size ?? 0,
            sizeUnit: mapStringToEnum(ApplicationSizeUnit, response?.sizeUnit?.toString() ?? "Unknown"),
            quantity: response?.quantity ?? 0,
            notes: response?.notes ?? "",
            currentStock: response?.currentStock ?? 0,
            plu: response?.plu ?? "",
            approvalStatus: mapStringToEnum(ApplicationApprovalStatus, response?.approvalStatus ?? "Unknown")
        }
    }

    async abandonBasket(basketId: number): Promise<void> {
        const { data, error } = await client.PATCH("/v{version}/api/basket/{basketId}/abandon", {
            params: {
                path: { version: "1", basketId },  // Path parameter             
            },
        });

        if (!data?.success || data.data == null || error) {
            throw new ApplicationError("Failed to abdandon basket");
        }
    }
    async insertBasketItem(basketId: number, basketItem: ApplicationBasketItem): Promise<number> {

        const { data, error } = await client.POST("/v{version}/api/basket/{basketId}/basketitem", {
            params: {
                path: { version: "1", basketId },  // Path parameter             
            },
            body: {
                basketId: basketId,
                productId: basketItem.productId,
                supplierId: basketItem.supplierId,
                notes: basketItem.notes,
                quantity: basketItem.quantity,
                currentStock: basketItem.currentStock,
                size: basketItem.size,
                sizeUnit: basketItem.sizeUnit,
                approvalStatus: basketItem.approvalStatus
            }
        });


        if (!data?.success || error || !data.data) {
            throw new ApplicationError("Failed to insert basket item");
        }

        return data.data.id ?? 0;
    }
    async updateBasketItem(basketItemId: number, quantity: number, currentStock: number, notes: string): Promise<void> {
        const { data, error } = await client.PATCH("/v{version}/api/basket/basketitem/{basketItemId}", {
            params: {
                path: { version: "1", basketItemId },  // Path parameter             
            },
            body: {
                notes,
                quantity,
                currentStock
            }
        });

        if (!data?.success || error || !data.data) {
            throw new ApplicationError("Failed to update basket item");
        }
    }

    async deleteBasketItem(basketItemId: number): Promise<void> {
        const { data, error } = await client.DELETE("/v{version}/api/basket/basketitem/{basketItemId}", {
            params: {
                path: { version: "1", basketItemId },  // Path parameter             
            }
        });

        if (!data?.success || error || !data.data) {
            throw new ApplicationError("Failed to update basket item");
        }
    }
}
