import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import TextInput from "../../components/controls/text-input";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showSuccess } from "../../components/notification/toastr-actions";
import { useAppSelector } from "../../hooks/useAppSelector";
import { requiredValidator } from "../../helpers/validators";
import { ApplicationDepartmentCategory } from "../../models/organisation/application-department-category";
import { setNewValueForTarget } from "../../store/features/organisation/admin-department-slice";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { duplicateValidator } from "../../helpers/validators";
import { MainContent, Footer, ButtonGroup } from "../../styles/shared/drawer";
import {
  updateDepartment,
  setSelectedCategory,
} from "../../store/features/organisation/admin-department-slice";
import { EditApplicationDepartment } from "../../models/organisation/edit-application-department";
import Checkbox from "../../components/controls/checkbox";

interface SupplierModalProps {
  id?: number;
}
interface Validation {
  categoryName?: string | null;
  categoryDescription?: string | null;
}

export default function DepartmentCategoryDrawer({ id }: SupplierModalProps) {
  const dispatch = useAppDispatch();
  const [validation, setValidation] = useState<Validation>({
    categoryName: null,
    categoryDescription: null,
  });
  const creatingCategory = useAppSelector(
    (state) => state.adminDepartment.creatingCategory
  );
  const selectedDepartment = useAppSelector(
    (state) => state.adminDepartment.selectedDepartment
  );
  const selectedCategory = useAppSelector(
    (state) => state.adminDepartment.selectedCategory
  );

  useEffect(() => {
    if (selectedDepartment && selectedDepartment.departmentCategories) {
      const category = selectedDepartment.departmentCategories.find(
        (cat) => cat.id === id
      );
      if (category) {
        dispatch(setSelectedCategory(category));
      }
      resetValidation();
    }
  }, [selectedDepartment, id]);


  const onChange = <K extends keyof ApplicationDepartmentCategory>(
    value: ApplicationDepartmentCategory[K],
    target: "Category",
    property: "Name" | "Description" | "Active",
    validation?: (input: ApplicationDepartmentCategory[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;
    dispatch(
      setNewValueForTarget({
        target: target,
        field: property,
        value: value,
      })
    );
    setValidation((prevState) => ({
      ...prevState,
      [target.toLowerCase() + property]: errorMessage,
    }));
  };

  const handleSaveCategories = async () => {
    if (!selectedDepartment || !validateCategoryFields()) return;
    if (creatingCategory) {
      const categoryNameError = duplicateValidator(
        selectedDepartment?.departmentCategories ?? [],
        "name",
        selectedCategory?.newName || "",
        selectedCategory?.id
      );
      if (categoryNameError) {
        setValidation((prevState) => ({
          ...prevState,
          categoryName: categoryNameError,
        }));
        return;
      }
    }
    const updatedDepartmentCategories =
      selectedDepartment.departmentCategories?.map((category) =>
        category.id === selectedCategory?.id
          ? {
            ...category,
            name: selectedCategory.newName || "",
            description: selectedCategory.newDescription || "",
            active: selectedCategory.newActive || false
          }
          : category
      ) || [];
    if (selectedCategory?.id === 0) {
      updatedDepartmentCategories.push({
        ...selectedCategory,
        name: selectedCategory.newName || "",
        description: selectedCategory.newDescription || "",
        active: selectedCategory.newActive || false
      });
    }
    const updatedDepartment: EditApplicationDepartment = {
      ...selectedDepartment,
      departmentCategories: updatedDepartmentCategories,
    };
    const response = await dispatch(updateDepartment(updatedDepartment));
    if (response) {
      dispatch(closeDrawer());
      dispatch(
        showSuccess(
          creatingCategory
            ? "New category has been successfully added."
            : "Department categories have been successfully updated."
        )
      );
    }
  };

  const validateCategoryFields = () => {
    const validation = {
      categoryName: requiredValidator(selectedCategory?.newName || ""),
      categoryDescription: requiredValidator(
        selectedCategory?.newDescription || ""
      ),
    };
    setValidation(validation);
    const hasErrors = Object.values(validation).some((error) => error !== null);
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };
  const resetValidation = () => {
    setValidation({
      categoryName: null,
      categoryDescription: null,
    });
  };

  if (selectedCategory == null) {
    return <></>
  }
  return (
    <>
      <MainContent>

        <Container>
          <CheckboxContainer>
            <Checkbox
              label="Active"
              checked={selectedCategory?.newActive}
              onChange={(event) => onChange(event.target.checked, "Category", "Active")}
            ></Checkbox>
          </CheckboxContainer>
          <TextInput
            label="Category Name"
            id="categoryNameInput"
            value={selectedCategory?.newName || ""}
            error={validation.categoryName}
            onChange={(event) =>
              onChange(
                event.target.value,
                "Category",
                "Name",
                requiredValidator
              )
            }
          />
          <TextInput
            // multiline={true}
            label="Category Description"
            id="categoryDescriptionInput"
            value={selectedCategory?.newDescription || ""}
            error={validation.categoryDescription}
            onChange={(event) =>
              onChange(
                event.target.value,
                "Category",
                "Description",
                requiredValidator
              )
            }
          />
        </Container>
      </MainContent>
      <Footer>
        <ButtonGroup>
          <Button
            label="Cancel"
            variant="outlined"
            id="btnCancelDrawer"
            onClick={() => dispatch(closeDrawer())}
          />
          <Button
            id="btnCreate"
            label={creatingCategory ? "Create" : "Update"}
            variant="contained"
            onClick={handleSaveCategories}
          />
        </ButtonGroup>
      </Footer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;