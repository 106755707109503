import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import Header from "../../components/layout/header";
import styled from "styled-components";
import {
  ActiveArea,
  ContentArea,
  HeaderArea,
  Main,
  Title,
  TitleArea,
  VerticalContent,
} from "../../styles/shared/page";
import ModalHandler from "../modals/modal-handler";
import Toastr from "../../components/notification/toastr";
import { useErrorDisplay } from "../../hooks/useErrorDisplay";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../../components/layout/back";
import ConfirmModal from "../../components/notification/confirm-modal";
import Download from "../../components/layout/download";
import DeliveryModeButton from "../../components/layout/delivery-mode";
import Button from "../../components/controls/button";
import { ICON_ID } from "../../components/icons/google-icon";
import DrawerHandler from "../drawer/drawer-handler";
import { ApplicationOrderStatus } from "../../models/order/application-order-status";
import { Alert } from "@mui/material";
interface LayoutProps {
  showBack: boolean;
  children: React.ReactNode;
}

const OrderLayout: React.FC<LayoutProps> = ({ showBack, children }) => {
  const user = useAppSelector((state) => state.user).user;
  const branch = useAppSelector((state) => state.branch).selectedBranch;
  const order = useAppSelector((state) => state.order).order;
  const deliveryMode = useAppSelector((state) => state.order).deliveryMode;
  const { orderId } = useParams();
  const navigate = useNavigate();
  useErrorDisplay();

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <DrawerHandler></DrawerHandler>
      <ConfirmModal></ConfirmModal>
      <Header name={user?.name} team={user?.role} selectedBranch={branch} />
      <VerticalContent>
        <HeaderArea>
          {showBack ? (
            <Back
              confirmMessage={
                deliveryMode ? "You have unsaved changes." : ""
              }
            ></Back>
          ) : (
            <Button
              id="btnDone"
              label="Home"
              icon={ICON_ID.ArrowBack}
              variant="text"
              onClick={() => navigate("/")}
            />
          )}
          <TitleArea>
            <Title>Order #{orderId}</Title>
            <TitleButtonContainer>
              {order?.status == ApplicationOrderStatus.Delivered && (
                <Alert severity="success">The order has been delivered</Alert>
              )}

              {!deliveryMode && <Download orderId={Number(orderId)} />}

              {order?.status == ApplicationOrderStatus.Active && (
                <DeliveryModeButton
                  orderId={Number(orderId)}
                  edit={deliveryMode}
                />
              )}
            </TitleButtonContainer>
          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>{children}</ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default OrderLayout;

const TitleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;


  button {
    margin-left: 20px; /* Space between title and button */
    margin-bottom: 0; /* No bottom margin by default */

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;
