import { useEffect } from "react";
import styled from "styled-components";
import TableView, {
  ColumnConfig,
} from "../../../components/controls/table-view";
import {
  ProductContainer,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../../styles/shared/product";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../../components/icons/google-icon";
import Image from "../../../components/controls/image";
import {
  fetchSupplierProducts,
  resetSearchProducts,
  resetSupplierProducts,
  resetUnmappedSelectedProduct,
  setSearch,
  setUnmappedSelectedProduct,
} from "../../../store/features/supplier/supplier-product-slice";
import Button from "../../../components/controls/button";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  SmallTextContainer,
  TextContainer,
} from "../../../styles/shared/table";
import { ApplicationSupplierProductHeader } from "../../../models/supplier/application-supplier-product-header";
import { openDrawer } from "../../../store/features/drawer/drawer-slice";
import { DrawerID } from "../../../constants/drawer-constants";
interface MapProductsProps {
  supplierId: number;
}
export default function MapProducts({ supplierId }: MapProductsProps) {
  const products = useAppSelector((state) => state.supplierProducts);

  const dispatch = useAppDispatch();
  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    productId: {
      hidden: true,
    },
    supplierId: {
      hidden: true,
    },
    description: {
      hidden: true,
    },
    image: {
      hidden: true,
    },
    newPrice: {
      hidden: true,
    },
    editPrice: {
      hidden: true,
    },
    price: {
      hidden: true,
    },
    supplierName: {
      hidden: true,
    },
    updated: {
      hidden: true,
    },
    active: {
      hidden: true,
    },
    plu: {
      hidden: true,
    },
    sizeUnit: {
      hidden: true
    },
    reference: {
      hidden: true
    },
    name: {
      name: "Product",
      order: 1,
      colspan: 2,
      render: (value: string, row: any) => (
        <ProductContainer>
          <ProductImage>
            {row.productId ? (
              <Image image={row.image}></Image>
            ) : (
              <GoogleIcon
                tooltip="Not mapped"
                id={ICON_ID.QuestionMark}
                isWarning
                size={ICON_SIZES.Large}
              />
            )}
          </ProductImage>
          <ProductNameContainer>
            <ProductTitle>{value} {row.reference ? ` - (${row.reference})` : ""}</ProductTitle>
            <SmallTextContainer> Plu: {row.plu}</SmallTextContainer>
          </ProductNameContainer>
        </ProductContainer>
      ),
    },
    size: {
      render: (value: string, row: any) => <TextContainer>{value} {row.sizeUnit}</TextContainer>,
    },
  };

  useEffect(() => {
    dispatch(resetSupplierProducts());
    dispatch(resetUnmappedSelectedProduct());
    return () => {
      dispatch(setSearch(""))
    };
  }, []);

  const fetchMoreData = async (search: boolean) => {
    await dispatch(fetchSupplierProducts({ supplierId, search: search, unmappedOnly: true }));
  };

  const renderActionButton = (row: any) => (
    <Button
      variant="text"
      icon={ICON_ID.ChevronRight}
      id={`btnSelect${row.name}`}
      label=""
      isSecondary={true}
      fullWidth={false}
      onClick={() => onSelected(row)}
    />
  );

  const onSelected = async (row: any) => {
    dispatch(
      setUnmappedSelectedProduct(row as ApplicationSupplierProductHeader)
    );
    dispatch(
      openDrawer({
        id: DrawerID.MapProduct,
        anchor: "right",
        data: {
          headerText: `Map ${row?.name}`,
          supplierId: supplierId,
        },
      })
    );
    dispatch(resetSearchProducts());
  };

  return (
    <Container>
      <TableView
        id="tblProducts"
        emptyText="No products"
        fetchMoreData={(search) => fetchMoreData(search ?? false)}
        onRowClick={onSelected}
        totalCount={products.products?.count ?? 0}
        data={products?.products?.products ?? []}
        columnConfig={columnConfig}
        actionButton={renderActionButton}

        showHeaders={true}
        setSearchText={(searchText) => dispatch(setSearch(searchText))}
        searchText={products.searchText}
      />
    </Container>
  );
}

const Container = styled.div`
  flex: 0 0 70vh;
  min-height: 0;
`;
