import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React, { useState } from 'react'
import TextButton from './text-button';

interface PasswordInputProps {
  id: string;
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  error: string | null;
}

export default function PasswordInput(properties: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  };

  return (
    <FormControl fullWidth error={!!properties.error} variant="outlined">
      <InputLabel htmlFor={properties.id}>Password</InputLabel>
      <OutlinedInput
        id={properties.id}
        value={properties.value}
        onChange={properties.onChange}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <TextButton label={showPassword ? 'Hide' : 'Show'} id="btnShowPassword" onClick={handleShowPassword} />
          </InputAdornment>
        }
        label={properties.label}
      />
      {properties.error && <FormHelperText>{properties.error}</FormHelperText>}
    </FormControl>
  )
}
