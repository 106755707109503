import { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../../components/layout/admin-sidebar";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  fetchDepartmentCategories,
  fetchDepartments
} from "../../store/features/organisation/department-slice";
import TableView from "../../components/controls/table-view";
import {
  TextContainer,
  UnderlinedTextContainer,
} from "../../styles/shared/table";

import Button from "../../components/controls/button";
import { ICON_ID } from "../../components/icons/google-icon";
import { ApplicationDepartment } from "../../models/organisation/application-department";
import { showSuccess } from "../../components/notification/toastr-actions";
import { ColumnConfig } from "../../components/controls/table-view";
import { DrawerID } from "../../constants/drawer-constants";
import { openDrawer } from "../../store/features/drawer/drawer-slice";
import {
  fetchCategories,
  fetchCategory,
  setSelectedDepartment,
  resetSelectedCategory,
  deleteCategory,
  removeCategory,
  resetCategory,
  setSelectedCategory,
  setSearch,
  resetSelectedDepartment,
} from "../../store/features/product/product-category-slice";
import {
  openConfirm,
  closeConfirm,
} from "../../store/features/notification/confirm-slice";
import useScreenSize from "../../hooks/useScreenSize";
import { useNavigate, useParams } from "react-router-dom";
import { resetProducts, resetSelectedProduct } from "../../store/features/product/admin-product-slice";
import { ApplicationCategory } from "../../models/product/application-category";
import { setCurrentPage } from "../../store/features/authentication/user-slice";

const AdminProductCategory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const departments = useAppSelector((state) => state.department);
  const categoryState = useAppSelector((state) => state.productCategory);
  const selectedDepartment = useAppSelector(
    (state) => state.productCategory.selectedDepartment
  );

  const { isMobile, isDesktop } = useScreenSize();

  useEffect(() => {
    dispatch(fetchDepartments()).then((action) => {
      if (
        fetchDepartments.fulfilled.match(action) &&
        action.payload.departments &&
        action.payload.departments.length > 0
        && selectedDepartment == null
      ) {
        const preselectDepartment = departmentId ? action.payload.departments.find(x => x.id == parseInt(departmentId)) : action.payload.departments[0];
        if (preselectDepartment) {
          handleDepartmentSelect(preselectDepartment);
        }
      }
    });


    dispatch(setCurrentPage("Manage Product Categories"))

    return () => {
      dispatch(resetSelectedDepartment());
      dispatch(resetSelectedCategory());
      dispatch(setSearch(""));
      dispatch(resetCategory());
    }
  }, [dispatch]);


  const fetchMoreData = async (search: boolean) => {
    if (categoryState.selectedDepartment && !categoryState.loading) {
      await dispatch(fetchCategories({
        departmentId: categoryState.selectedDepartment.id,
        search,
      }));
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      dispatch(fetchDepartmentCategories(selectedDepartment.id))
    }
  }, [selectedDepartment])

  const handleDepartmentSelect = (department: ApplicationDepartment) => {
    if (selectedDepartment?.id !== department.id) {
      dispatch(resetCategory());
      dispatch(fetchCategories({ departmentId: department.id, search: false }));
      dispatch(setSearch(""));
    }

    dispatch(setSelectedDepartment(department));
  };

  const openNewCategory = () => {
    dispatch(resetSelectedCategory());
    dispatch(
      openDrawer({
        id: DrawerID.ProductCategory,
        anchor: "right",
        data: {
          headerText: "Add New Category",
        },
      })
    );
  };

  const handleDeleteCategoryClick = (row: any) => {
    const onConfirm = async () => {
      const response = await dispatch(deleteCategory(row.id));
      if (response) {
        dispatch(removeCategory(row.id));
        dispatch(closeConfirm());
        dispatch(showSuccess(`${row.name} deleted successfully.`));
      }
    };
    const onCancel = () => {
      dispatch(closeConfirm());
    };
    dispatch(
      openConfirm({
        title: "Remove " + row.name + "?",
        message: "Are you sure you want to delete this category?",
        show: true,
        data: {
          onConfirm: onConfirm,
          onCancel: onCancel,
        },
      })
    );
  };

  const handleViewProducts = (row: any) => {
    dispatch(resetProducts());
    dispatch(setSelectedCategory(row as ApplicationCategory));
    navigate(`/admin/${row.departmentId}/category/${row.id}/products`);
  };

  const handleAddProduct = (row: any) => {
    dispatch(resetProducts());
    dispatch(resetSelectedProduct());
    dispatch(setSelectedCategory(row as ApplicationCategory));
    dispatch(
      openDrawer({
        id: DrawerID.AdminProduct,
        anchor: "right",
        data: {
          headerText: `Create the first product for ${row.name}`,
          departmentId: selectedDepartment?.id,
          categoryId: row.id
        },
      })
    );
  }

  const columnConfig: { [key: string]: ColumnConfig } = {
    departmentId: {
      hidden: true,
    },
    departmentCategoryId: {
      hidden: true,
    },
    expand: {
      hidden: true,
    },
    name: {
      order: 1,
      render: (value: number) => <TextContainer>{value}</TextContainer>,
    },
    departmentCategoryName: {
      order: 2,
      name: "Department Category",
      render: (value: number) => <TextContainer>{value}</TextContainer>,
    },
    productCount: {
      name: "Items",
      hideOnMobile: true,
      order: 3,
      render: (value: number) => <div>{value} products</div>,
    },
    id: {
      order: 4,
      hidden: isMobile && true,
      name: "",
      render: (value: string, row: any) =>
        row.productCount > 0 ? (
          <UnderlinedTextContainer onClick={() => handleViewProducts(row)}>
            View Products
          </UnderlinedTextContainer>
        ) : (
          <UnderlinedTextContainer onClick={() => handleAddProduct(row)}>
            Add Product
          </UnderlinedTextContainer>
        ),
    },
  };

  const EditUser = async (row: any) => {
    const response = await dispatch(fetchCategory(row.id));
    if (response) {
      dispatch(
        openDrawer({
          id: DrawerID.ProductCategory,
          anchor: "right",
          data: {
            headerText: "Edit category",
          },
        })
      );
    }
  };
  const renderActionButton = (row: any) => {
    return (
      <ButtonWrapper>
        {isMobile && row.productCount > 0 && (
          <Button
            variant="text"
            label=""
            id={row.Name}
            icon={ICON_ID.Inventory}
            isPrimary={true}
            fullWidth={false}
            onClick={() => handleViewProducts(row)}
          />
        )}
        {row.productCount <= 0 && (
          <Button
            variant={!isDesktop ? "text" : "outlined"}
            label={!isDesktop ? "" : "Delete"}
            id={row.Name}
            icon={!isDesktop ? ICON_ID.Delete : undefined}
            isDanger={true}
            fullWidth={false}
            onClick={() => handleDeleteCategoryClick(row)}
          />
        )}
        <Button
          variant={!isDesktop ? "text" : "outlined"}
          id={row.id}
          label={!isDesktop ? "" : "Edit"}
          icon={!isDesktop ? ICON_ID.Edit : undefined}
          isPrimary={true}
          fullWidth={false}
          onClick={() => EditUser(row)}
        />
      </ButtonWrapper>
    );
  };

  const renderToolbar = () => (
    <Button
      variant="contained"
      icon={ICON_ID.Add}
      id="btnCreateNewCategory"
      label="New category"
      fullWidth={false}
      onClick={openNewCategory}
    />
  );

  return (
    <Container>
      <NavigationSection>
        <AdminSidebar
          items={(departments.departments?.departments ?? []).map((department) => ({
            label: department.name,
            action: () => handleDepartmentSelect(department),
            isSelected: department.id == selectedDepartment?.id
          }))}
        ></AdminSidebar>
      </NavigationSection>
      <ContentSection>
        <ContentTitle>
          <FieldsGridContainer>
            <DepartmentCategoryTitle>
              {selectedDepartment?.name || ""}
            </DepartmentCategoryTitle>
          </FieldsGridContainer>
        </ContentTitle>
        <Body>
          <TableContainer>
            <TableView
              id="departmentCategoryTable"
              emptyText="No department categories"
              toolbar={renderToolbar}
              fetchMoreData={(search) => fetchMoreData(search ?? false)}
              totalCount={categoryState?.categories?.count ?? 0}
              data={categoryState?.categories?.categories ?? []}
              columnConfig={columnConfig}
              actionButton={renderActionButton}
              showHeaders={true}
              setSearchText={(searchText) => dispatch(setSearch(searchText))}
              searchText={categoryState.searchText}
            />
          </TableContainer>
        </Body>
      </ContentSection>
    </Container>
  );
};

const Body = styled.div`
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  margin: 10px;
  column-gap: 20px;
  height: 100%;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

const DepartmentCategoryTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;
const TableContainer = styled.div`
  padding: 5px;
  height: 90%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const ContentTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 2px solid ${(props) => props.theme.application.scheme.primary};
  padding: 5px 0px 5px 5px;
`;

const NavigationSection = styled.div`
  min-width: 15%;
`;

const ContentSection = styled.div`
  display: flex;
  box-shadow: ${(props) => props.theme.application.boxShadow};
  flex-direction: column;
  width: 100%;
  padding: 8px;
  background: ${(props) => props.theme.application.background};
  border-radius: 4px;
  height: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    max-width: 100px;
  }

  @media (max-width: 500px) {
    gap: 0;
  }
`;

const FieldsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
export default AdminProductCategory;
