import styled from "styled-components";
import { ReactNode, useState } from "react";

type AdminListItem = {
  label: string;
  action: () => void;
  highlightItem?: boolean;
  isSelected?: boolean;
};

interface AdminSidebarProps {
  items: AdminListItem[];
  children?: ReactNode;
}

export default function AdminSidebar({ items, children }: AdminSidebarProps) {

  const handleClick = (label: string, action: () => void, highlightItem?: boolean) => {
    action();
  };

  return (
    <Container>
      {children}
      {items.map((x) => (
        <ListItem
          key={x.label}
          onClick={() => handleClick(x.label, x.action, x.highlightItem)}
          selected={x.isSelected == true}
        >
          {x.label}
        </ListItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.application.boxShadow};

  @media (max-width: 1600px) {
    flex-direction: row;
    margin-bottom: 10px;
    div {
      border-right: 1px solid ${(props) => props.theme.application.border};
    }
  }

  background: ${(props) => props.theme.application.background};
  border-radius: 5px;
  text-align: center;
  overflow-x: auto;
  
    &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.application.product.background};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.application.scrollBar};
    border: 2px solid ${(props) => props.theme.application.scrollBar};
    border-radius: 2px;
  }
`;

const ListItem = styled.div<{ selected: boolean }>`
  padding: 15px;
  cursor: pointer;
  min-width: 100px;

  background: ${({ selected, theme }) =>
    selected ? theme.application.hover : "transparent"};

  font-weight: ${({ selected }) =>
    selected ? "500" : "400"};


  @media (min-width: 1600px) {
    border-bottom: 1px solid ${(props) => props.theme.application.border};
  }

  &:hover {
    background: ${(props) => props.theme.application.table.hover};
  }
`;
