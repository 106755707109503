import { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import TextInput from "../../components/controls/text-input";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { ApplicationAdminUser } from "../../models/organisation/application-user";
import {
  requiredValidator,
  emailValidator,
  arrayRequired,
} from "../../helpers/validators";
import {
  FeatureCardRow,
  FeatureCard,
  FeatureCardTitle,
  FeatureCardSingleColumn,
} from "../../styles/shared/card";
import { SelectChangeEvent } from "@mui/material/Select";
import { MainContent, Footer, ButtonGroup } from "../../styles/shared/drawer";
import Dropdown from "../../components/controls/dropdown";
import { showSuccess } from "../../components/notification/toastr-actions";
import {
  insertUser,
  updateUser,
  checkDuplicateEmail,
} from "../../store/features/organisation/admin-user-slice";
import Checkbox from "../../components/controls/checkbox";
import { ApplicationRole } from "../../models/authentication/application-roles";
interface Validation {
  name: string | null;
  username: string | null;
  role: string | null;
  branches: string | null;
}

export default function AdminUserDrawer() {
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector((state) => state.adminUser).selectedUser;
  const branches = useAppSelector((state) => state.branch).branches;

  const [validation, setValidation] = useState<Validation>({
    name: null,
    username: null,
    role: null,
    branches: null,
  });
  const [branchOptions, setBranchOptions] = useState<
    { value: string; label: string }[]
  >([{ value: "", label: "" }]);

  const roleOptions = Object.values(ApplicationRole).map((role) => ({
    value: role,
    label: role,
  }));

  const defaultUser: ApplicationAdminUser = {
    id: 0,
    username: "",
    name: "",
    role: "Unknown",
    active: true,
    lastLogin: new Date(),
    branches: [],
  };

  const [user, setUser] = useState<ApplicationAdminUser>(
    selectedUser || defaultUser
  );

  useEffect(() => {
    if (branches) {
      setBranchOptions(
        branches.map((branch) => ({
          value: branch.id.toString(),
          label: branch.name,
        }))
      );
    }
  }, [branches]);

  const getBranchSelectionName = (selected: string[]) => {
    if (selected.length > 1) {
      return "Multiple selected";
    }

    var selectedBranches = branches
      ?.filter((x) => selected.indexOf(x.id.toString()) != -1)
      .map((branch) => branch.name)
      .join(", ");
    return selectedBranches;
  };

  const onChange = <K extends keyof ApplicationAdminUser>(
    value: ApplicationAdminUser[K],
    property: K,
    validation?: (input: ApplicationAdminUser[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;

    setUser((prevState) => ({
      ...prevState,
      [property]: value,
    }));

    setValidation((prevState) => ({
      ...prevState,
      [property]: errorMessage,
    }));
  };

  const handleBranchSelection = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;

    const branchInput =
      typeof value === "string" ? value.split(",") : (value as string[]);
    const foundBranches = branches?.filter(
      (x) => branchInput.indexOf(x.id.toString()) != -1
    );

    setUser((prevState) => ({
      ...prevState,
      branches: foundBranches ?? [],
    }));

    setValidation((prevState) => ({
      ...prevState,
      branches: arrayRequired(foundBranches ?? []),
    }));
  };

  const handleRoleSelection = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    const selectedRole = value as ApplicationRole;
    setUser((prevState) => ({
      ...prevState,
      role: selectedRole,
    }));

    setValidation((prevState) => ({
      ...prevState,
      role: requiredValidator(selectedRole),
    }));
  };

  const onUpdate = async () => {
    if (!validateAndCheckErrors()) {
      return;
    }
    if (user.id === 0 && user.username) {
      const emailExists = await dispatch(checkDuplicateEmail(user.username));
      if (emailExists.payload) {
        setValidation((prev) => ({
          ...prev,
          username: "Email already exists",
        }));
        return;
      }
    }
    let notificationMessage = `${user.name} `;
    if (user.id == 0) {
      await dispatch(insertUser(user));
      notificationMessage += "created";
    } else {
      await dispatch(updateUser(user));
      notificationMessage += "updated";
    }
    dispatch(showSuccess(notificationMessage));
    dispatch(closeDrawer());
  };
  const validateAndCheckErrors = () => {
    const validation = {
      name: requiredValidator(user.name),
      username: emailValidator(user.username),
      role: requiredValidator(user.role),
      branches: arrayRequired(user.branches),
    };
    setValidation(validation);
    const hasErrors = Object.values(validation).some((error) => error !== null);
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <MainContent>
        <Container>
          <CheckboxContainer>
            <Checkbox
              label="Active"
              checked={user.active}
              onChange={(event) => onChange(event.target.checked, "active")}
            ></Checkbox>
          </CheckboxContainer>
          <TextInput
            id="txtEmail"
            label="Email"
            value={user.username}
            error={validation.username}
            onChange={(event) =>
              onChange(event.target.value, "username", (input) =>
                emailValidator(input)
              )
            }
            disabled={user.id !== 0}
          />
          <TextInput
            id="txtUserName"
            value={user.name}
            label="Name"
            onChange={(event) =>
              onChange(event.target.value, "name", (input) =>
                requiredValidator(input)
              )
            }
            error={validation.name}
          />

          <Dropdown
            id="cboRole"
            variant="outlined"
            label="Select Role"
            options={roleOptions}
            onChange={handleRoleSelection}
            value={user.role}
            errorMessage={validation.role}
            multiple={false}
            autoWidth={false}
            native={false}
          />

          <Dropdown
            id="cboBranch"
            variant="outlined"
            label="Select branch"
            renderValue={(selected) =>
              getBranchSelectionName(selected as string[])
            }
            options={branchOptions}
            onChange={handleBranchSelection}
            value={user.branches?.map((x) => x.id.toString()) ?? []}
            errorMessage={validation.branches}
            multiple
            autoWidth={false}
            native={false}
          />
        </Container>
      </MainContent>
      <Footer>
        <ButtonGroup>
          <Button
            label="Cancel"
            variant="outlined"
            id="btnCancelDrawer"
            onClick={() => dispatch(closeDrawer())}
          />
          <Button
            id="btnCreate"
            label={user.id == 0 ? "Create" : "Update"}
            variant="contained"
            onClick={onUpdate}
          />
        </ButtonGroup>
      </Footer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
