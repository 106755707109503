import { Link } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

interface LinkInputProps {
  id: string;
  href: string;
  label: string;
}

export default function AnchorLink(properties: LinkInputProps) {
  return (
    <Link sx={{ fontWeight: 600 }} href={properties.href} id="id">{properties.label}</Link>
  )
}

