import { useEffect } from 'react';
import useScreenSize from './useScreenSize';

const useEnterKeyListener = (callback: () => void): void => {

    const { isDesktop } = useScreenSize()
    useEffect(() => {
        if (isDesktop) {
            const listener = (event: KeyboardEvent) => {
                if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                    callback();
                    event.preventDefault();
                }
            };
            document.addEventListener('keydown', listener);
            return () => {
                document.removeEventListener('keydown', listener);
            };
        }
    }, [callback]);
};

export default useEnterKeyListener;
