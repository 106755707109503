import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationAdminUser } from "../../models/organisation/application-user";
import { ApplicationUserList } from "../../models/organisation/application-user-list";
import { ApplicationBranch } from "../../models/organisation/application-branch";
import { components } from "../../external/schema/order-link-api-schema"; // Adjust import as necessary
import client from "../../external/open-api";
import IUserService from "./user-service-interface"; // Ensure you have this interface defined

export default class UserService implements IUserService {
  async getUsers(
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean,
    searchText: string
  ): Promise<ApplicationUserList> {
    const { data, error } = await client.GET("/v{version}/api/user", {
      params: {
        path: { version: "1" },
        query: {
          "Paging.CurrentPosition": currentPosition,
          "Paging.FetchNext": fetchNext,
          "Paging.FetchAll": fetchAll,
          SearchText: searchText,
        },
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch users");
    }

    return {
      users: this.mapToApplicationAdminUsers(data.data.users ?? []),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async getUser(userId: number): Promise<ApplicationAdminUser> {
    const { data, error } = await client.GET("/v{version}/api/user/{userId}", {
      params: {
        path: { version: "1", userId },
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch supplier");
    }

    return this.mapToApplicationAdminUser(
      data.data as components["schemas"]["UserDTO"]
    );
  }

  async getBranches(): Promise<ApplicationBranch[]> {
    const { data, error } = await client.GET("/v{version}/api/user/branch", {
      params: {
        path: { version: "1" },
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch branches");
    }

    return data.data.map((branch: components["schemas"]["BranchDTO"]) => ({
      id: branch.id ?? 0,
      name: branch.name ?? "",
    }));
  }
  async insertUser(user: ApplicationAdminUser): Promise<ApplicationAdminUser> {
    const userDto = this.mapToUserDTO(user);
    const { data, error } = await client.POST("/v{version}/api/user", {
      params: {
        path: { version: "1" },
      },
      body: {
        ...userDto,
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to insert user");
    }

    return this.mapToApplicationAdminUser(data.data ?? {});
  }

  async updateUser(user: ApplicationAdminUser): Promise<ApplicationAdminUser> {
    const userDto = this.mapToUserDTO(user);
    const { data, error } = await client.PUT("/v{version}/api/user", {
      params: {
        path: { version: "1" },
      },
      body: {
        ...userDto,
      },
    });
    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to update user");
    }

    return this.mapToApplicationAdminUser(data.data ?? {});
  }
  async checkDuplicateEmail(email: string): Promise<boolean> {
    const { data, error } = await client.GET("/v{version}/api/user/search", {
      params: {
        path: { version: "1" },
        query: { email },
      },
    });

    if (error || !data?.success) {
      throw new ApplicationError("Failed to check duplicate email");
    }

    return Boolean(data.data);
  }

  private mapToApplicationAdminUser(
    user: components["schemas"]["UserDTO"]
  ): ApplicationAdminUser {
    return {
      id: user.id ?? 0,
      username: user.username ?? "",
      name: user.name ?? "",
      role: user.role ?? "",
      active: user.active ?? false,
      lastLogin: user.lastLogin ? new Date(user.lastLogin) : new Date(),
      branches:
        user.branches?.map((branch) => ({
          id: branch.id ?? 0,
          name: branch.name ?? "",
        })) || [],
    };
  }

  private mapToApplicationAdminUsers(
    users: components["schemas"]["UserDTO"][]
  ): ApplicationAdminUser[] {
    return users.map(this.mapToApplicationAdminUser);
  }
  private mapToUserDTO(
    user: ApplicationAdminUser
  ): components["schemas"]["UserDTO"] {
    const validRoles: Array<
      "Unknown" | "SupplierManager" | "StoreManager" | "Owner"
    > = ["Unknown", "SupplierManager", "StoreManager", "Owner"];
    return {
      id: user?.id ?? 0,
      name: user.name,
      username: user.username,
      active: user.active,
      role: validRoles.includes(user.role as any)
        ? (user.role as any)
        : "Unknown",
      branches: user.branches,
    };
  }
}
