import { Autocomplete, CircularProgress, TextField, } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface SearchDropdownProps<T> {
    fetchOptions: (query: string) => Promise<void>; // Function to fetch options
    options: T[],
    clearOptions: () => void
    label: string; // Label for the TextField
    isOptionEqualToValue: (option: T, value: T) => boolean; // Function to compare options
    renderOption: (props: React.HTMLProps<HTMLLIElement>, option: T) => React.ReactNode; // Function to render each option
    onChange?: (value: T | null) => void;
}



const SearchDropdown = <T,>({ fetchOptions, label, isOptionEqualToValue, renderOption, options, clearOptions, onChange }: SearchDropdownProps<T>) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    React.useEffect(() => {

        if (!open) {
            clearOptions();
            return;
        }

        if (timeoutId) {
            clearTimeout(timeoutId);
        }


        if (searchQuery == "" || searchQuery.length > 2) {
            setLoading(true);
            const newTimeoutId = setTimeout(() => {

                fetchOptions(searchQuery)
                    .then(() => {
                        setLoading(false);
                    })
                    .catch(() => {
                        clearOptions();
                        setLoading(false);
                    });
            }, 1000);

            setTimeoutId(newTimeoutId);
        } else {
            clearOptions();
        }

    }, [open, searchQuery]);



    return (
        <Autocomplete
            options={options}
            loading={loading}
            open={open}
            getOptionLabel={(option: any) => option.name || ""}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={renderOption}
            fullWidth={true}
            filterOptions={x => x}
            sx={
                { minWidth: "300px" }
            }
            onInputChange={(_, newValue) => setSearchQuery(newValue)}
            onChange={(_, newValue: T | null) => {
                if (onChange) {
                    onChange(newValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default SearchDropdown;
