import DepartmentCategoryDrawer from "../pages/drawer/department-category-drawer";
import DepartmentDrawer from "../pages/drawer/department-drawer";
import MapProductDrawer from "../pages/drawer/map-product-drawer";
import AdminUserDrawer from "../pages/drawer/admin-user-drawer";
import ProductCategoryDrawer from "../pages/drawer/product-category-drawer";
import SupplierDrawer from "../pages/drawer/supplier-drawer";
import AdminProductDrawer from "../pages/drawer/admin-product-drawer";
import SupplierProductDrawer from "../pages/drawer/supplier-product-drawer";
export enum DrawerID {
  DepartmentCategory = 1,
  Department = 2,
  MapProduct = 3,
  AdminUser = 4,
  ProductCategory = 5,
  Supplier = 6,
  AdminProduct = 7,
  SupplierProduct = 8,
}

export const DrawerComponents: Record<DrawerID, React.ComponentType<any>> = {
  [DrawerID.DepartmentCategory]: DepartmentCategoryDrawer,
  [DrawerID.Department]: DepartmentDrawer,
  [DrawerID.MapProduct]: MapProductDrawer,
  [DrawerID.AdminUser]: AdminUserDrawer,
  [DrawerID.ProductCategory]: ProductCategoryDrawer,
  [DrawerID.Supplier]: SupplierDrawer,
  [DrawerID.AdminProduct]: AdminProductDrawer,
  [DrawerID.SupplierProduct]: SupplierProductDrawer,
};
