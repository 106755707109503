import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchSuppliers, resetSuppliers, setSearch } from '../../store/features/supplier/supplier-slice';
import styled from 'styled-components';
import TableView, { ColumnConfig } from '../../components/controls/table-view';
import Button from '../../components/controls/button';
import { TableContainer, TextContainer } from '../../styles/shared/table';
import { SmallSpacer } from '../../styles/shared/spacing';
import { GoogleIcon, ICON_ID } from '../../components/icons/google-icon';
import { useNavigate } from 'react-router-dom';
import { ApplicationBranch } from '../../models/organisation/application-branch';
import { openDrawer } from '../../store/features/drawer/drawer-slice';
import { DrawerID } from '../../constants/drawer-constants';
import { useEffect } from 'react';

export default function SupplierHome() {
    const suppliers = useAppSelector(state => state.supplier);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const fetchMoreData = async (search?: boolean) => {
        await dispatch(fetchSuppliers({ search: search ?? false }))
    }

    const renderActionButton = (row: any) => (
        <Button variant="outlined" id={row.Name} label='View' isPrimary={true} fullWidth={false} onClick={() => navigate(`/supplier/${row.id}`)} />
    );

    useEffect(() => {
        return () => {
            dispatch(setSearch(""))
            // a bit of hack, resetting the suppliers causes lots of issues, so whenever this component unmounts, 
            // just reload fresh data for next time.  we set search to true to reset the paging.
            dispatch(fetchSuppliers({ search: true }))
        }
    }, [])

    const columnConfig: { [key: string]: ColumnConfig } = {
        id: {
            hidden: true,
        },
        name: {
            order: 1,
        },
        noOfProducts: {
            order: 2,
            hideOnMobile: true,
            name: "Products",
            render: (value: string) => (
                <TextContainer>
                    {value} products
                </TextContainer>
            )
        },
        addressLine1: {
            order: 3,
            hideOnMobile: true,
            name: "Address",
            render: (value: string, row: any) => (
                <TextContainer>
                    <div>
                        {value}
                    </div>
                    <div>
                        {row.addressLine2}
                    </div>
                    <div>
                        {row.addressLine3}
                    </div>
                    <div>
                        {row.city}
                    </div>
                    <div>
                        {row.postcode}
                    </div>
                </TextContainer>
            )
        },
        phone: {
            order: 4,
            hideOnMobile: true,
            name: "Contact",
            render: (value: string, row: any) => (
                <TextContainer>
                    <div><a href={`tel:${value}`}>{value}</a></div>
                    <SmallSpacer></SmallSpacer>
                    <div><a href={`mailto:${row.email}`}>{row.email}</a></div>
                </TextContainer>
            )
        },
        branches: {
            order: 5,
            name: "Branch",
            render: (value: ApplicationBranch[]) => (
                <TextContainer>
                    {value.map((x) => (
                        <div>
                            <GoogleIcon id={ICON_ID.Domain} ></GoogleIcon>{x.name}
                            <SmallSpacer />
                        </div>
                    ))}
                </TextContainer>
            )
        },
        updated: {
            order: 6,
            hideOnMobile: true,
            render: (value: Date) => (
                <TextContainer>
                    {new Date(value).toLocaleDateString()} {new Date(value).toLocaleTimeString()}
                </TextContainer>
            )
        },
        email: {
            hidden: true,
        },
        addressLine2: {
            hidden: true
        },
        addressLine3: {
            hidden: true
        },
        city: {
            hidden: true
        },
        postcode: {
            hidden: true
        },
    };

    const openNewSupplier = () => {
        dispatch(
            openDrawer({
                id: DrawerID.Supplier,
                anchor: "right",
                data: {
                    headerText: "Create New Supplier",
                },
            }));
    }

    const renderToolbar = () => (
        <Button id="createNewSupplier" onClick={openNewSupplier} label="New Supplier" variant="contained" icon={ICON_ID.Add}></Button>
    )

    return (

        <TableContainer>
            <TableView
                id="No suppliers"
                toolbar={renderToolbar}
                emptyText='No suppliers'
                fetchMoreData={(search) => fetchMoreData(search)}
                totalCount={suppliers.suppliers?.count ?? 0}
                data={suppliers?.suppliers?.suppliers ?? []}
                columnConfig={columnConfig}
                actionButton={renderActionButton}
                showHeaders={true}
                searchText={suppliers.searchText}
                setSearchText={(value) => dispatch(setSearch(value))}
            />
        </TableContainer>

    )
}
