export enum ApplicationDay {
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
    Sunday = "Sunday",
}

export type ApplicationDeliveryDay = {
    date: Date,
    day: ApplicationDay
}

// Define the correct order of days
const dayOrder = [
    ApplicationDay.Monday,
    ApplicationDay.Tuesday,
    ApplicationDay.Wednesday,
    ApplicationDay.Thursday,
    ApplicationDay.Friday,
    ApplicationDay.Saturday,
    ApplicationDay.Sunday,
];

// Function to compare two days
export function sortDays(day1: ApplicationDay, day2: ApplicationDay): number {
    return dayOrder.indexOf(day1) - dayOrder.indexOf(day2);
}
