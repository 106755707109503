import { useState } from 'react';
import { ICON_ID } from '../icons/google-icon';
import styled, { } from 'styled-components';
import Button from './button';

interface SplitButtonInputProps {
    id: string;
    label: string;
    fullWidth?: boolean | false;
    isPrimary?: boolean | true;
    isDanger?: boolean | false;
    isWarning?: boolean | false;
    isSecondary?: boolean | false;
    isNeutral?: boolean | false;
    loading?: boolean | false;
    icon?: ICON_ID | null;
    variant: 'text' | 'outlined' | 'contained';
    disabled?: boolean | false;
    hidden?: boolean | false;
    options: SplitOptions[]
}

export interface SplitOptions {
    key: string,
    value: string,
    onClick: () => void;
    special: boolean
}


export default function SplitButton({ id, label, fullWidth, isPrimary, isDanger, isWarning, isSecondary, isNeutral, loading, icon, variant, disabled, hidden, options }: SplitButtonInputProps) {

    const [open, setOpen] = useState(false);

    const onOptionClick = (option: SplitOptions) => {
        setOpen(false)
        option.onClick();

    }
    return (
        <ButtonContainer>
            <Button id={id}
                label={label}
                fullWidth={fullWidth}
                isPrimary={isPrimary}
                isDanger={isDanger}
                isWarning={isWarning}
                isSecondary={isSecondary}
                isNeutral={isNeutral}
                loading={loading}
                icon={icon}
                variant={variant}
                disabled={disabled}
                onClick={() => setOpen(!open)}
                hidden={hidden} />

            {open && (
                <OptionContainer>
                    {options.map((x) => {
                        return <Option special={x.special} onClick={() => onOptionClick(x)} key={x.key}> {x.value}</Option>
                    })}
                </OptionContainer>
            )}
        </ButtonContainer>
    )

}

const ButtonContainer = styled.div`
    position:relative;
`

const OptionContainer = styled.div`
    position:absolute;
    z-index: 3;
    background: ${(props) => props.theme.application.background};
    width:100%;
    display: flex;
    flex-direction:column;
    box-shadow: ${(props) => props.theme.application.boxShadow};
    border:1px solid  ${(props) => props.theme.application.border};
    border-radius: 0px 0px 3px 3px;

`
interface OptionProps {
    special: boolean
}

const Option = styled.div<OptionProps>`
    padding:10px;
    border-bottom:1px solid ${(props) => props.theme.application.hover};
    border-radius: 3px;
    ${(props) => props.special && (
        `
        background: ${props.theme.application.scheme.secondary};
        color: ${props.theme.application.textContrast};

           border-radius: 0px;
        `
    )};
    
     ${(props) => !props.special && (
        `
            &:hover{
                background: ${props.theme.application.hover};
            } 
        `
    )}; 
`