import { ApplicationError } from "../../models/errors/application-error";
import IOrderService from "./order-service-interface";
import { ApplicationOrderList } from "../../models/order/application-order-list";
import { ApplicationOrder } from "../../models/order/application-order";
import { ApplicationOrderLineList } from "../../models/order/application-order-line-list";
import { ApplicationApprovalStatus } from "../../models/order/application-approval-status";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationOrderLine } from "../../models/order/application-order-line";
import client from "../../external/open-api";
import { ApplicationOrderLineUpdate } from "../../models/order/application-order-line-update";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
export default class OrderService implements IOrderService {

  async create(basketId: number): Promise<ApplicationOrder> {
    const { data, error } = await client.POST("/v{version}/api/order", {
      params: {
        path: { version: "1" },
      },
      body: {
        basketId,
      },
    });

    if (!data || error) {
      throw new ApplicationError("Api call failed creating order");
    }

    const response = await this.handleResponse(data, "Failed to create order");
    return this.formatOrder(response ?? {});
  }

  async getDelivered(
    branchId: number,
    currentPosition: number,
    fetchNext: number,
    searchText: string
  ): Promise<ApplicationOrderList> {
    const { data, error } = await client.GET(
      "/v{version}/api/order/branch/{branchId}/delivered",
      {
        params: {
          path: { version: "1", branchId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": false,
            "SearchText": searchText
          },
        },
      }
    );

    if (!data || error) {
      throw new ApplicationError("Api call failed fetching delivered orders");
    }

    const response = await this.handleResponse(
      data,
      "Failed to fetch delivered orders"
    );

    const orders = response.orders?.map(this.formatOrder);

    return {
      orders: orders ?? [],
      count: response?.count || 0,
      currentPosition: response?.currentPosition || 0,
    };
  }
  async get(
    branchId: number,
    currentPosition: number,
    fetchNext: number,
    searchText: string
  ): Promise<ApplicationOrderList> {
    const { data, error } = await client.GET(
      "/v{version}/api/order/branch/{branchId}",
      {
        params: {
          path: { version: "1", branchId }, // Path parameter
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": false,
            "SearchText": searchText
          },
        },
      }
    );

    if (!data || error) {
      throw new ApplicationError("Api call failed fetching orders");
    }

    const response = await this.handleResponse(data, "Failed to fetch orders");

    const orders = response.orders?.map(this.formatOrder);

    return {
      orders: orders ?? [],
      count: response?.count || 0,
      currentPosition: response?.currentPosition || 0,
    };
  }

  async getById(orderId: number): Promise<ApplicationOrder> {
    const { data, error } = await client.GET(
      "/v{version}/api/order/{orderId}",
      {
        params: {
          path: { version: "1", orderId },
        },
      }
    );

    if (!data || error) {
      throw new ApplicationError("Api call failed fetching order");
    }

    const response = await this.handleResponse(data, "Failed to fetch order");
    return this.formatOrder(response ?? {});
  }
  async updateOrder(
    orderId: number,
    orders: ApplicationOrderLineUpdate[],
    completeOrder: boolean,
  ): Promise<ApplicationOrderLineList> {
    const { data, error } = await client.PATCH(
      "/v{version}/api/order/{orderId}/supplier",
      {
        params: {
          path: { version: "1", orderId },
          query: { completeOrder }
        },
        body: {
          orders,
        },
      }
    );

    if (!data || error) {
      throw new ApplicationError("Api call failed updating order line");
    }

    const response = await this.handleResponse(
      data,
      "Failed to update order supplier"
    );

    const orderLines =
      response.orderLines?.map(this.mapOrderLine.bind(this)) ?? [];

    return {
      orderLines,
      count: response.count || 0,
      currentPosition: response.currentPosition || 0,
    };
  }

  async getOrderPDF(orderId: number): Promise<Blob> {
    const { data, error, response } = await client.GET(
      "/v{version}/api/order/{orderId}/pdf",
      {
        params: {
          path: { version: "1", orderId },
        },
      }
    );

    if (response?.status === 204) {
      throw new ApplicationError("No order PDF found");
    }

    if (!data?.data?.file || error) {
      throw new ApplicationError("Api call failed fetching order PDF");
    }

    // Safely decode the base64 file content
    const byteCharacters = atob(data.data.file);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "application/pdf" });
  }

  async getOrderLines(
    orderId: number,
    currentPosition: number,
    fetchNext: number,
    searchText: string
  ): Promise<ApplicationOrderLineList> {
    const { data, error } = await client.GET(
      "/v{version}/api/order/{orderId}/orderlines",
      {
        params: {
          path: { version: "1", orderId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": false,
            "SearchText": searchText
          },
        },
      }
    );

    if (!data || error) {
      throw new ApplicationError("Api call failed fetching order");
    }

    const response = await this.handleResponse(
      data,
      "Failed to fetch order lines"
    );

    const orderLines =
      response.orderLines?.map(this.mapOrderLine.bind(this)) ?? [];
    return {
      orderLines,
      count: response.count || 0,
      currentPosition: response.currentPosition || 0,
    };
  }

  private async handleResponse<T>(
    response: { success?: boolean; data?: T },
    errorMessage: string
  ): Promise<T> {
    if (!response.success) {
      throw new ApplicationError(errorMessage);
    }
    return response.data!;
  }

  private formatOrder(order: any): ApplicationOrder {
    return {
      id: order.id,
      lineCount: order.lineCount,
      displayName: `Order #${order.id}`,
      created: order.created,
      status: order.status
    };
  }
  private mapOrderLine(orderLine: any): ApplicationOrderLine {
    return {
      id: orderLine.id ?? 0,
      productId: orderLine.productId ?? 0,
      productName: orderLine.productName ?? "",
      productDescription: orderLine.productDescription ?? "",
      productImage: orderLine.productImage ?? "",
      size: orderLine.size ?? 0,
      sizeUnit: mapStringToEnum(ApplicationSizeUnit, orderLine.sizeUnit ?? ""),
      quantity: orderLine.quantity ?? 0,
      notes: orderLine.notes ?? "",
      plu: orderLine.plu ?? "",
      approvalStatus: mapStringToEnum(
        ApplicationApprovalStatus,
        orderLine.approvalStatus ?? ""
      ),
    };
  }
}
