export const emailValidator = (email: string): string | null => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) ? null : "Invalid email address";
};

export const minLengthValidator = (
  input: string,
  minLength: number
): string | null => {
  if (input.length < minLength) {
    return `Enter more than ${minLength} characters`;
  }
  return null;
};

export const arrayRequired = (input: any[]): string | null => {
  if (!input || input.length < 1) {
    return `Required`;
  }
  return null;
};

export const requiredValidator = (input: any): string | null => {
  if (!input || input.length < 1) {
    return `Required`;
  }
  return null;
};

export const minValueValidator = (
  input: number | null | undefined,
  minValue: number = 1,
  message: string = `Enter a value greater than ${minValue - 1}`
): string | null => {
  if (input == null || isNaN(input) || input < minValue) {
    return message;
  }
  return null;
};
export const maxValueValidator = (
  input: number | null | undefined,
  maxValue: number = 0,
  message: string = `Enter a value less than ${maxValue}`
): string | null => {
  if (input == null || input > maxValue) {
    return message;
  }
  return null;
};

export const postcodeValidator = (postcode: string): string | null => {
  const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]?\s*\d[A-Z]{2}$/i;

  // Trim the postcode and ensure it matches the UK postcode pattern
  const trimmedPostcode = postcode.trim();

  // Validate against the regex
  if (postcodeRegex.test(trimmedPostcode)) {
    return null; // Valid postcode
  } else {
    return "Invalid postcode"; // Invalid postcode
  }
};

export const phoneValidator = (phone: string): string | null => {
  // Remove spaces, dashes, and other common separators
  const cleanedPhone = phone.replace(/[\s()-]/g, "");

  // Regular expression for UK landline and mobile numbers
  const phoneRegex = /^(?:0(?:1\d{9}|2\d{9}|3\d{9}|7\d{9}|8\d{9}|9\d{9}))$/;

  if (phoneRegex.test(cleanedPhone)) {
    return null; // Valid UK phone number
  } else {
    return "Invalid phone number"; // Invalid phone number
  }
};

export const matchValidator = (
  value1: any,
  value2: any,
  allowNulls: boolean,
  message: string = `The values do not match`
): string | null => {
  if (allowNulls && !value2) {
    return null;
  }

  if (value1 !== value2) {
    return message;
  }
  return null;
};

export const duplicateValidator = (
  collection: any[],
  key: string,
  newValue: string,
  excludeKey: string | number | null = null,
  excludeKeyField: string = "id",
  message: string = "Value already exists"
): string | null => {
  const isValueExist = collection?.some((item) => {
    if (excludeKey && item[excludeKeyField] === excludeKey) {
      return false;
    }
    return item[key]?.trim().toLowerCase() === newValue?.trim().toLowerCase();
  });

  if (isValueExist) {
    return message;
  }

  return null;
};
