import "./App.css";
import Home from "./pages/home";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/login";
import { GuestRoute } from "./components/routing/guest-route";
import { HomeRoute } from "./components/routing/home-route";
import { DepartmentRoute } from "./components/routing/department-route";
import ProductSelection from "./pages/products/product-selection";
import Basket from "./pages/products/basket";
import { BasketRoute } from "./components/routing/basket-route";
import { OrderRoute } from "./components/routing/order-route";
import OrderView from "./pages/orders/order-view";
import { OwnerRoute } from "./components/routing/owner-route";
import AdminHome from "./pages/admin/admin-home";
import AdminApprovals from "./pages/admin/admin-approvals";
import { SupplierRoute } from "./components/routing/supplier-route";
import SupplierHome from "./pages/supplier/supplier-home";
import SupplierView from "./pages/supplier/supplier-view";
import AdminDepartment from "./pages/admin/admin-department";
import Reset from "./pages/auth/reset";
import AdminUser from "./pages/admin/admin-user";
import AdminProductCategory from "./pages/admin/admin-product-category";
import AdminMasterProductView from "./pages/admin/admin-master-product-view";
import AdminAllProductView from "./pages/admin/admin-all-product-view";
function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/login" element={<GuestRoute />}>
          <Route index element={<Login />} />
          <Route path="reset" element={<Reset />} />
        </Route>

        <Route path="/" element={<HomeRoute />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/:departmentParam/products" element={<DepartmentRoute />}>
          <Route index element={<ProductSelection />} />
        </Route>
        <Route path="/basket" element={<BasketRoute />}>
          <Route index element={<Basket />} />
        </Route>
        <Route
          path="/order/:orderId/confirmation"
          element={<OrderRoute showBack={false} />}
        >
          <Route index element={<OrderView />} />
        </Route>
        <Route path="/order/:orderId" element={<OrderRoute showBack={true} />}>
          <Route index element={<OrderView />} />
        </Route>

        <Route path="/admin" element={<OwnerRoute />}>
          <Route index element={<AdminHome />} />
          <Route path="approvals" element={<AdminApprovals />} />
          <Route path="department" element={<AdminDepartment />} />
          <Route path="user" element={<AdminUser />} />
          <Route path="category">
            <Route index element={<AdminProductCategory />} />
            <Route path=":departmentId" element={<AdminProductCategory />} />
          </Route>
          <Route path=":departmentId/category" element={<AdminProductCategory />} />
          <Route path="products" element={<AdminAllProductView />} />
          <Route
            path=":departmentId/category/:categoryId/products"
            element={<AdminMasterProductView />}
          />
        </Route>

        <Route path="/supplier" element={<SupplierRoute />}>
          <Route index element={<SupplierHome />} />
          <Route path="/supplier/:supplierId" element={<SupplierView />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
