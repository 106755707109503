import { ApplicationError } from "../../models/errors/application-error";
import IProductCategoryService from "./product-category-service-interface";
import { ApplicationCategory } from "../../models/product/application-category";
import { ApplicationCategoryList } from "../../models/product/application-category-list";
import client from "../../external/open-api";
import { components } from "../../external/schema/order-link-api-schema";

export default class ProductCategoryService implements IProductCategoryService {
  async getCategoriesByDepartment(
    departmentId: number,
    currentPosition: number,
    fetchNext: number,
    searchText?: string,
    fetchAll?: boolean,
  ): Promise<ApplicationCategoryList> {
    const { data, error } = await client.GET(
      "/v{version}/api/category/department/{departmentId}",
      {
        params: {
          path: { version: "1", departmentId: departmentId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": fetchAll,
            SearchText: searchText,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch categories");
    }

    return {
      categories: this.mapToCategories(data.data, departmentId),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }
  async getCategory(categoryId: number): Promise<ApplicationCategory> {
    const { data, error } = await client.GET(
      "/v{version}/api/category/{categoryId}",
      {
        params: {
          path: { version: "1", categoryId },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch supplier");
    }
    return this.mapToCategory(
      data.data as components["schemas"]["CategoryDTO"]
    );
  }

  async insertCategory(
    category: ApplicationCategory
  ): Promise<ApplicationCategory> {
    const { data, error } = await client.POST("/v{version}/api/category", {
      params: {
        path: { version: "1" },
      },
      body: {
        departmentCategoryId: category.departmentCategoryId,
        name: category.name,
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to create category");
    }

    return this.mapToCategory(
      data.data as components["schemas"]["CategoryDTO"]
    );
  }
  async updateCategory(
    category: ApplicationCategory
  ): Promise<ApplicationCategory> {
    const { data, error } = await client.PUT("/v{version}/api/category", {
      params: {
        path: { version: "1" },
      },
      body: {
        id: category.id,
        departmentCategoryId: category.departmentCategoryId,
        name: category.name,
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to update category");
    }

    return this.mapToCategory(
      data.data as components["schemas"]["CategoryDTO"]
    );
  }

  async deleteCategory(categoryId: number): Promise<void> {
    const { data, error } = await client.DELETE("/v{version}/api/category", {
      params: {
        path: { version: "1" },
        query: {
          categoryId: categoryId,
        },
      },
    });

    if (!data?.success || error) {
      throw new ApplicationError("Failed to delete category");
    }
  }

  private mapToCategory(
    category: components["schemas"]["CategoryDTO"]
  ): ApplicationCategory {
    return {
      id: category.id ?? 0,
      departmentCategoryId: category.departmentCategoryId ?? 0,
      name: category.name ?? "",
      departmentCategoryName: category.deparmentCategoryName ?? "",
      productCount: category.productCount ?? 0,
      expand: false,
    };
  }
  private mapToCategories(
    data: components["schemas"]["GetCategoriesResponse"] | null,
    departmentId: number
  ): ApplicationCategory[] {
    if (!data) {
      return [];
    }

    return (
      data.categories?.map((d) => ({
        id: d.id ?? 0,
        departmentId: departmentId,
        departmentCategoryId: d.departmentCategoryId ?? 0,
        name: d.name ?? "",
        departmentCategoryName: d.deparmentCategoryName ?? "",
        productCount: d.productCount ?? 0,
        expand: false,
      })) || []
    );
  }
}
