import { ICON_ID } from "../icons/google-icon";
import { useNavigate } from "react-router-dom";
import Button from "../controls/button";
import styled from "styled-components";
import {
  closeConfirm,
  openConfirm,
} from "../../store/features/notification/confirm-slice";
import { useAppDispatch } from "../../hooks/useAppDispatch";

interface BackButtonProps {
  confirmMessage?: string;
}

export default function Back({ confirmMessage }: BackButtonProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (confirmMessage) {
      handleConfirmModal();
    } else {
      navigate(-1);
    }
  };

  const handleConfirmModal = () => {
    dispatch(
      openConfirm({
        title: "Confirmation",
        message: `${confirmMessage} Are you sure you want to leave?`,
        show: true,
        data: {
          onConfirm: () => handleConfirm(),
          onCancel: () => handleCancel(),
        },
      })
    );
  };

  const handleConfirm = () => {
    navigate(-1);
    dispatch(closeConfirm());
  };

  const handleCancel = () => {
    dispatch(closeConfirm());
  };

  return (
    <ButtonWrapper>
      <Button
        variant="text"
        icon={ICON_ID.ArrowBack}
        label="Back"
        id="btnBack"
        isPrimary={false}
        onClick={onClick}
      />
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  margin-left:10px;
  button {
    font-size: 1rem;
    span {
      font-size: 1.2rem;
    }
  }
`;
