import React from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { generatePDF } from "../../store/features/ordering/order-slice";
import Button from "../../components/controls/button";

interface DownloadButtonProps {
  orderId: number;
}

const Download: React.FC<DownloadButtonProps> = ({ orderId }) => {
  const dispatch = useAppDispatch();

  const handleDownload = () => {
    dispatch(generatePDF(orderId)).then((resultAction) => {
      if (generatePDF.fulfilled.match(resultAction)) {
        const pdfBlob = resultAction.payload;
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const newWindow = window.open(pdfUrl);
        if (!newWindow) {
          console.error("Failed to open new window");
        }
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `Order_${orderId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <Button
      variant="contained"
      id="btnDownloadPDF"
      label="Download PDF"
      onClick={handleDownload}
    />
  );
};

export default Download;
