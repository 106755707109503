import React from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import Button from "../../components/controls/button";
import {
  toggleDeliveryMode,
  resetChanges,
} from "../../store/features/ordering/order-slice";
import { updateOrderline } from "../../store/features/ordering/order-slice";
import { ApplicationOrderLineUpdate } from "../../models/order/application-order-line-update";
interface DeliveryModeButtonProps {
  orderId: number;
  edit: boolean;
}
const DeliveryModeButton: React.FC<DeliveryModeButtonProps> = ({
  orderId,
  edit,
}) => {
  const dispatch = useAppDispatch();
  const orderLines = useAppSelector((state) => state.order).orderLines;

  const handleToggleDeliveryMode = () => {
    dispatch(toggleDeliveryMode());
  };

  const handleCancel = () => {
    dispatch(resetChanges());
  };

  const handleSaveChanges = (completeOrder: boolean) => {
    const orders: ApplicationOrderLineUpdate[] =
      orderLines?.orderLines.map((line) => ({
        productId: line.productId,
        quantityReceived: Number(line.quantityReceived) || 0,
        deliveryNotes: line.deliveryNotes || "",
      })) || [];

    dispatch(updateOrderline({ orderId, orders, completeOrder }));
  };

  return (
    <div>
      {!edit ? (
        <Button
          variant="contained"
          id="btnDeliveryMode"
          label="Delivery Mode"
          onClick={handleToggleDeliveryMode}
        />
      ) : (
        <>
          <Button
            variant="contained"
            isPrimary={false}
            isSecondary={true}
            id="btnCompleteChange"
            label="Complete"
            onClick={() => handleSaveChanges(true)}
          />
          <Button
            variant="outlined"
            id="btnSaveChanges"
            label="Save as Draft"
            onClick={() => handleSaveChanges(false)}
          />
          <Button
            variant="outlined"
            id="btnCancel"
            label="Cancel"
            onClick={handleCancel}
          />
        </>
      )}
    </div>
  );
};

export default DeliveryModeButton;
