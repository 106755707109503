import { useState, useEffect } from 'react';

type ScreenSize = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const useScreenSize = (): ScreenSize => {
  const getScreenSize = (): ScreenSize => {
    const width = window.innerWidth;

    return {
      isMobile: width < 700,
      isTablet: width >= 700 && width < 1024,
      isDesktop: width >= 1024,
    };
  };

  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize());

  useEffect(() => {
    const handleResize = () => {
      const newScreenSize = getScreenSize();
      // Update only if screen size actually changes
      if (
        newScreenSize.isMobile !== screenSize.isMobile ||
        newScreenSize.isTablet !== screenSize.isTablet ||
        newScreenSize.isDesktop !== screenSize.isDesktop
      ) {
        setScreenSize(newScreenSize);
      }
    };

    // Throttle the resize event listener for performance
    const debounceResize = debounce(handleResize, 100); // Adjust debounce time as needed

    window.addEventListener('resize', debounceResize);
    handleResize(); // Initialize the size on load

    return () => {
      window.removeEventListener('resize', debounceResize);
    };
  }, [screenSize]);

  return screenSize;
};

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}


export default useScreenSize;
