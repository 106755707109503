import { Outlet, Navigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import BasketLayout from '../../pages/layouts/basket-layout';
import OrderLayout from '../../pages/layouts/order-layout';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchOrder, resetOrder } from '../../store/features/ordering/order-slice';
import { ApplicationRole } from '../../models/authentication/application-roles';

interface OrderRouteProps {
    showBack: boolean
}
export const OrderRoute = ({ showBack }: OrderRouteProps) => {
    const { initialized, loading, authenticated, role } = useAuth();
    const { orderId } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (orderId) {
            dispatch(resetOrder());
            dispatch(fetchOrder(parseFloat(orderId)))
        }
    }, [orderId])

    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }

    if (role == ApplicationRole.SupplierManager) {
        return <Navigate to="/supplier" replace />;
    }

    return (
        <OrderLayout showBack={showBack}>
            <Outlet />
        </OrderLayout>
    )
};