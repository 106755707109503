import React, { useEffect } from 'react'
import styled from 'styled-components'
import TableView, { ColumnConfig } from '../../components/controls/table-view'
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchApprovals, resetApprovals } from '../../store/features/ordering/approval/approval-slice';
import { ProductContainer, ProductImage, ProductNameContainer, ProductTitle } from '../../styles/shared/product';
import { currencyFormatter } from '../../helpers/formatters';
import Image from '../../components/controls/image';
import Button from '../../components/controls/button';
import { openModal } from '../../store/features/modal/modal-slice';
import { ModalID } from '../../constants/modal-constants';
import { TextContainer, TableContainer } from '../../styles/shared/table';
import { setCurrentPage } from '../../store/features/authentication/user-slice';

export default function AdminApprovals() {
    const approval = useAppSelector(state => state.approval);
    const dispatch = useAppDispatch();

    const fetchMoreData = async () => {
        await dispatch(fetchApprovals())
    }

    useEffect(() => {
        dispatch(setCurrentPage("Approvals"))
        return () => {
            dispatch(resetApprovals());
        }
    }, [])


    const columnConfig: { [key: string]: ColumnConfig } = {
        id: {
            hidden: true
        },
        productId: {
            hidden: true,
        },
        productDescription: {
            hidden: true
        },
        productSize: {
            hidden: true
        },
        productImage: {
            hidden: true
        },
        orderId: {
            order: 2,
            name: "Order",
            render: (value: number) => (
                <TextContainer>Order <span className="bold">#{value}</span></TextContainer>
            )
        },
        productName: {
            order: 1,
            name: "Product",
            colspan: 3,
            render: (value: number, row: any) => (
                <ProductContainer>
                    <ProductImage>
                        <Image image={row.productImage}></Image>
                    </ProductImage>
                    <ProductNameContainer>
                        <ProductTitle>{value}</ProductTitle>
                        <div>{row.productSize} - {row.productDescription}</div>
                    </ProductNameContainer>
                </ProductContainer>
            )
        },
        notes: {
            hidden: true,
        },
        quantity: {
            order: 3,
            render: (value: number, row: any) => (
                <TextContainer>
                    Qty: {value}
                </TextContainer>
            )
        },
        username: {
            name: "Ordered by",
            order: 5,
            render: (value: string) => (
                <TextContainer>
                    {value}
                </TextContainer>
            )
        },
        branchName: {
            name: "Branch",
            order: 6,
            render: (value: string) => (
                <TextContainer>
                    {value}
                </TextContainer>
            )
        },
        created: {
            order: 7,
            render: (value: Date) => (
                <TextContainer>
                    {new Date(value).toLocaleString()}
                </TextContainer>
            )
        },
        approvalStatus: {
            hidden: true,
        },
        branchId: {
            hidden: true,
        }

    };

    const openApproval = (orderLineId: number) => {
        dispatch(openModal({
            id: ModalID.ActionRequest,
            data: { orderLineId }
        }));

    }

    const renderActionButton = (row: any) => (
        <Button variant="outlined" id={row.Name} label='View Request' isPrimary={true} fullWidth={false} onClick={() => openApproval(row.id)} />
    );

    return (
        <ApprovalContainer>
            <TableContainer>
                <TableView
                    id="ordersTable"
                    emptyText='No approvals '
                    fetchMoreData={fetchMoreData}
                    totalCount={approval.approvals?.count ?? 0}
                    data={approval?.approvals?.approvals ?? []}
                    columnConfig={columnConfig}
                    actionButton={renderActionButton}
                    showHeaders={true}
                />
            </TableContainer>
        </ApprovalContainer>
    )
}


const ApprovalContainer = styled.div`
    height:100%;
`
