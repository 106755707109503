import { useState } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import TextInput from "../../components/controls/text-input";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { requiredValidator } from "../../helpers/validators";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { useAppSelector } from "../../hooks/useAppSelector";
import Dropdown from "../../components/controls/dropdown";
import { SelectChangeEvent } from "@mui/material";
import { createDepartment } from "../../store/features/organisation/admin-department-slice";
import { showSuccess } from "../../components/notification/toastr-actions";
import { addDepartment } from "../../store/features/organisation/department-slice";
import { ApplicationDepartment } from "../../models/organisation/application-department";
import { duplicateValidator } from "../../helpers/validators";
import { MainContent, Footer, ButtonGroup } from "../../styles/shared/drawer";
interface DepartmentValidation {
  departmentName?: string | null;
}

export default function DepartmentDrawer() {
  const dispatch = useAppDispatch();
  const [validation, setValidation] = useState<DepartmentValidation>({
    departmentName: null,
  });
  const departments = useAppSelector((state) => state.department.departments)?.departments ?? [];
  const [departmentName, setDepartmentName] = useState<string>("");
  const [productMode, setProductMode] = useState<string>("Default");

  const handleDepartmentNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setDepartmentName(value);

    const error = requiredValidator(value);
    setValidation((prevState) => ({
      ...prevState,
      departmentName: error,
    }));
  };

  const handleProductModeChange = (event: SelectChangeEvent<unknown>) => {
    setProductMode(event.target.value as string);
  };

  const handleCreate = async () => {
    const departmentNameError = requiredValidator(departmentName);
    if (departmentNameError) {
      setValidation((prevState) => ({
        ...prevState,
        departmentName: departmentNameError,
      }));
      return;
    }
    const duplicateNameError = duplicateValidator(
      departments,
      "name",
      departmentName,
      null
    );
    if (duplicateNameError) {
      setValidation((prevState) => ({
        ...prevState,
        departmentName: duplicateNameError,
      }));
      return;
    }
    const response = await dispatch(
      createDepartment({
        name: departmentName,
        productSelectionMode: productMode,
      })
    );
    if (response) {
      const newDepartment = response.payload as ApplicationDepartment;
      dispatch(addDepartment(newDepartment));
      dispatch(closeDrawer());
      dispatch(showSuccess("Department created successfully."));
    }
  };

  return (
    <>
      <MainContent>
        <Container>
          <TextInput
            label="Department Name"
            id="DepartmentNameInput"
            value={departmentName}
            error={validation.departmentName}
            onChange={handleDepartmentNameChange}
          />
          <Dropdown
            label="Product Mode"
            id="ProductModeDropdown"
            value={productMode}
            options={[
              { value: "Default", label: "Default" },
              { value: "Continous", label: "Continous" },
            ]}
            variant="outlined"
            onChange={handleProductModeChange}
            autoWidth={false}
            multiple={false}
            native={false}
          />
        </Container>
      </MainContent>
      <Footer>
        <ButtonGroup>
          <Button
            label="Cancel"
            variant="outlined"
            id="btnCancelDrawer"
            onClick={() => dispatch(closeDrawer())}
          />
          <Button
            id="btnCreate"
            label="Create"
            variant="contained"
            onClick={handleCreate}
          />
        </ButtonGroup>
      </Footer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
