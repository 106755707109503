import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationUserList } from "../../../models/organisation/application-user-list";
import UserService from "../../../services/organisation/user-service";
import { RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import { ApplicationAdminUser } from "../../../models/organisation/application-user";
import AuthenticationService from "../../../services/authentication/authentication-service";
export const fetchUsers = createAsyncThunk<
  ApplicationUserList | null,
  { search: boolean },
  { state: RootState }
>("users/getAllUsers", async ({ search }, { dispatch, getState, rejectWithValue }) => {
  const state = getState();
  const userService = new UserService();

  try {
    var currentPosition: number = state.adminUser?.users?.currentPosition ?? 0;
    var fetchNext: number = 15;
    var count: number = state.adminUser.users?.count ?? -1;

    if (search) {
      currentPosition = 0;
      count = -1;
      dispatch(resetUsers());
    }

    if (currentPosition != count) {
      return await userService.getUsers(currentPosition, fetchNext, false, state.adminUser.searchText);
    }
    return null;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const fetchUser = createAsyncThunk<
  ApplicationAdminUser | null,
  number,
  { state: RootState }
>("users/getSingle", async (userId, { rejectWithValue }) => {
  const userService = new UserService();
  try {
    return await userService.getUser(userId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const insertUser = createAsyncThunk<
  ApplicationAdminUser | null,
  ApplicationAdminUser,
  { state: RootState }
>("users/insert", async (user, { rejectWithValue }) => {
  const userService = new UserService();

  try {
    return await userService.insertUser(user);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const updateUser = createAsyncThunk<
  ApplicationAdminUser | null,
  ApplicationAdminUser,
  { state: RootState }
>("users/update", async (user, { rejectWithValue }) => {
  const userService = new UserService();

  try {
    return await userService.updateUser(user);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const sendResetLink = createAsyncThunk<
  void,
  { email: string },
  { state: RootState }
>("reset/resetLink", async ({ email }, thunkApi) => {
  try {
    const authenticationService = new AuthenticationService();
    await authenticationService.sendReset(email, null, null);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return thunkApi.rejectWithValue(apiError);
  }
});

export const checkDuplicateEmail = createAsyncThunk<
  boolean,
  string,
  { state: RootState }
>("users/checkDuplicateEmail", async (email, { rejectWithValue }) => {
  const userService = new UserService();
  try {
    return await userService.checkDuplicateEmail(email);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});
interface UserState {
  users: ApplicationUserList | null;
  selectedUser: ApplicationAdminUser | null;
  loading: boolean;
  searchText: string;
}

const initialState: UserState = {
  users: null,
  selectedUser: null,
  loading: false,
  searchText: ""
};
const userSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    resetSelectedUser: (state) => {
      state.selectedUser = initialState.selectedUser;
    },
    resetUsers: (state) => {
      state.users = initialState.users;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchUsers.fulfilled,
        (state, action: PayloadAction<ApplicationUserList | null>) => {
          if (action.payload) {
            if (state.users?.users) {
              state.users.users = state.users.users.concat(
                action.payload!.users
              );
              state.users.currentPosition = action.payload!.currentPosition;
              state.users.count = action.payload!.count;
            } else {
              state.users = action.payload;
            }
          }
        }
      )
      .addCase(
        fetchUser.fulfilled,
        (state, action: PayloadAction<ApplicationAdminUser | null>) => {
          if (action.payload) {
            state.selectedUser = action.payload;
          }
        }
      )
      .addCase(
        insertUser.fulfilled,
        (state, action: PayloadAction<ApplicationAdminUser | null>) => {
          if (action.payload) {
            if (state.users?.users) {
              state.users.users.unshift(action.payload);
              state.users.count += 1;
              state.users.currentPosition += 1;
            }
          }
        }
      )
      .addCase(
        updateUser.fulfilled,
        (state, action: PayloadAction<ApplicationAdminUser | null>) => {
          if (action.payload) {
            const updatedUser = action.payload;
            if (state.selectedUser?.id === updatedUser.id) {
              state.selectedUser = updatedUser;
            }
            if (state.users?.users) {
              state.users.users = state.users.users.map((user) =>
                user.id === updatedUser.id ? updatedUser : user
              );
            }
          }
        }
      );
  },
});

export const { resetSelectedUser, setSearchText, resetUsers } = userSlice.actions;
export default userSlice.reducer;
