import React from "react";
import { GoogleIcon, ICON_SIZES } from "../icons/google-icon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { MenuItem, SxProps } from "@mui/material";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";

interface SizeInputProps {
    id: string;
    label: string;
    valueNumber: number;
    valueUnit: ApplicationSizeUnit;
    onChange?: (newValue: { number: number; unit: ApplicationSizeUnit }) => void;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    error?: string | null;
    iconID?: number;
    disabled?: boolean;
    multiline?: boolean;
    maxRows?: number;
    autoFocus?: boolean;
    sx?: SxProps;
    rows?: number;
}

export default function SizeInput(properties: SizeInputProps) {
    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newNumber = Number(event.target.value);

        if (!Number.isNaN(newNumber)) {
            if (properties.onChange) {
                properties.onChange({ number: newNumber, unit: properties.valueUnit });
            }
        }
    };

    const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newUnit = event.target.value as ApplicationSizeUnit;
        if (properties.onChange) {
            properties.onChange({ number: properties.valueNumber, unit: newUnit });
        }
    };

    return (
        <TextField
            id={properties.id}
            label={properties.label}
            disabled={properties.disabled ?? false}
            variant="outlined"
            type="text"
            onChange={handleNumberChange}
            onBlur={properties.onBlur}
            margin="normal"
            fullWidth
            error={!!properties.error}
            helperText={properties.error}
            value={properties.valueNumber}
            InputProps={{
                inputMode: "text",
                startAdornment:
                    properties.iconID !== undefined ? (
                        <InputAdornment position="start">
                            <GoogleIcon size={ICON_SIZES.Medium} id={properties.iconID} />
                        </InputAdornment>
                    ) : null,
                endAdornment: (
                    <InputAdornment position="end">
                        <TextField
                            select
                            value={properties.valueUnit == ApplicationSizeUnit.Unknown ? ApplicationSizeUnit.KG : properties.valueUnit}
                            onChange={handleUnitChange}
                            variant="standard"
                            sx={{ minWidth: 60 }}
                        >
                            {Object.values(ApplicationSizeUnit).filter(x => x != ApplicationSizeUnit.Unknown).map((unit) => (
                                <MenuItem key={unit} value={unit} >
                                    {unit}
                                </MenuItem>
                            ))}
                        </TextField>
                    </InputAdornment>
                ),
            }}
        />
    );
}
