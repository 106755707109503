import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../../components/layout/admin-sidebar";
import SupplierProducts from "./supplier-views/supplier-products";
import MapProducts from "./supplier-views/map-products";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import UploadSupplierProducts from "./supplier-views/upload-supplier-product";
import { openDrawer } from "../../store/features/drawer/drawer-slice";
import { DrawerID } from "../../constants/drawer-constants";
import { ApplicationSupplierProductsList } from "../../models/supplier/application-supplier-product-list";

export default function SupplierView() {
  const supplier = useAppSelector((state) => state.supplier).selectedSupplier;
  const [contentSection, setContentSection] = useState<React.ReactNode>(null);
  const [contentTitle, setContentTitle] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (supplier?.id) {
      setContent(<SupplierProducts supplierId={supplier!.id} />, "Products");
    }

  }, [supplier?.id]);

  const setContent = (element: React.ReactNode, title: string) => {
    setContentSection(element);
    setContentTitle(title);
  };
  const handleUploadSuccess = (data: ApplicationSupplierProductsList) => {
    if (data.products.filter(x => x.productId == 0).length > 0) {
      setContent(<MapProducts supplierId={supplier!.id} />, "Map Products");
      return;
    }
    setContent(<SupplierProducts supplierId={supplier!.id} />, "Products");
  };

  return (
    <Container>
      <NavigationSection>
        <AdminSidebar
          items={[
            {
              label: "Products",
              action: () =>
                setContent(
                  <SupplierProducts supplierId={supplier!.id} />,
                  "Products"
                ),
              isSelected: contentTitle == "Products"
            },
            {
              label: "Add new product",
              highlightItem: false,
              isSelected: false,
              action: () =>
                dispatch(
                  openDrawer({
                    id: DrawerID.SupplierProduct,
                    anchor: "right",
                    data: {
                      id: 0,
                      headerText: "Add product",
                    },
                  })
                )
            },
            {
              label: "Upload products",
              isSelected: contentTitle == "Upload Products",
              action: () =>
                setContent(
                  <UploadSupplierProducts
                    supplierId={supplier!.id}
                    handleUploadSuccess={handleUploadSuccess}
                  />,
                  "Upload Products"
                ),
            },
            {
              label: "Map products",
              isSelected: contentTitle == "Map products",
              action: () =>
                setContent(
                  <MapProducts supplierId={supplier!.id} />,
                  "Map products"
                ),
            },
            {
              label: "Update details",
              isSelected: false,
              action: () =>
                dispatch(
                  openDrawer({
                    id: DrawerID.Supplier,
                    anchor: "right",
                    data: {
                      headerText: `Edit ${supplier?.name}`,
                      id: supplier?.id
                    },
                  }))
            },
          ]}
        />
      </NavigationSection>

      <ContentSection>
        <ContentTitle>{contentTitle}</ContentTitle>
        {contentSection}
      </ContentSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 10px;
  column-gap: 20px;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

const ContentTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid ${(props) => props.theme.application.scheme.primary};
  padding: 10px;
`;

const NavigationSection = styled.div`
  min-width: 15%;
`;

const ContentSection = styled.div`
  display: flex;
  box-shadow: ${(props) => props.theme.application.boxShadow};
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  padding: 8px;
  background: ${(props) => props.theme.application.background};
  border-radius: 4px;
`;
