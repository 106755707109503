import styled from "styled-components"

export const ProductImage = styled.div`
    width:30px;
    height:30px;

     @media (min-width: 760px) {
        width:40px;
        height:40px;
    }
    margin-right:15px;

    span{
    margin-top:10px;
    }

`

export const ProductDescription = styled.div`
    display:none;
    @media (min-width: 760px) {
        display:block;
    }
`
export const ProductTitle = styled.div`
font-size:1.1rem;
font-weight:600;
`
export const ProductContainer = styled.div`
    display:flex;
    align-items: center;
    span{
        font-size:2rem;
    }

    div.approvalicon{
        display:none;
         @media (min-width: 760px) {
            display:block;
        }
    }
`
export const ProductNameContainer = styled.div`
    display:flex;
   flex-direction:column;   
`