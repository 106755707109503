import { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { ScrollableCard } from "../../styles/shared/card";
import { ActiveArea } from "../../styles/shared/page";
import TextInput from "../../components/controls/text-input";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../components/icons/google-icon";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  fetchCategoriesByDepartment,
  fetchProducts,
  searchProducts,
  setLoadingCategory,
  setSearchString,
  toggleExpand,
  updateBranchProduct,
} from "../../store/features/product/product-slice";
import { ApplicationCategory } from "../../models/product/application-category";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import ProductList from "../../components/controls/product-list";
import { blurAnimation } from "../../styles/shared/loading";
import { closeModal, openModal } from "../../store/features/modal/modal-slice";
import { ModalID } from "../../constants/modal-constants";
import LoadingSpinner from "../../components/controls/loading-spinner";
import { showSuccess } from "../../components/notification/toastr-actions";
import {
  closeConfirm,
  openConfirm,
} from "../../store/features/notification/confirm-slice";
import { TableContainer } from "../../styles/shared/table";
import useScreenSize from "../../hooks/useScreenSize";
import { fetchBasketItems } from "../../store/features/ordering/basket/basket-slice";

export default function ProductSelection() {
  const theme = useTheme();
  const products = useAppSelector((state) => state.product);
  const basket = useAppSelector((state) => state.basket);
  const branch = useAppSelector((state) => state.branch).selectedBranch;
  const department = useAppSelector(
    (state) => state.department
  ).selectedDepartment;
  const dispatch = useAppDispatch();
  const placeholdersCount =
    (products.categories?.count || 0) -
    (products.categories?.categories.length || 0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const loadingRowRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useScreenSize();
  const [columnCount, setColumnCount] = useState(10);
  const setLoadingCategories = (loading: boolean) => {
    dispatch(setLoadingCategory(loading));
  };


  useEffect(() => {
    dispatch(fetchBasketItems({ basketId: basket.basket!.id, search: false }));
  }, [basket.basket]);

  const loadCategories = async () => {
    await dispatch(fetchCategoriesByDepartment(department!.id));
  };

  useEffect(() => {
    loadCategories();

    return () => {
      dispatch(setSearchString(""));
    }
  }, [department]);

  useInfiniteScroll({
    dataLength: products.categories?.categories.length || 0,
    totalCount: products.categories?.count || 0,
    fetchMoreData: loadCategories,
    setLoading: setLoadingCategories,
    loading: products.categories?.loading || false,
    loadingRowRef,
  });

  const handleToggleExpand = (category: ApplicationCategory) => {
    dispatch(fetchProducts(category!.id));
    dispatch(toggleExpand(category));
  };

  const handleProductClick = (
    id: number,
    categoryId: number,
    productName: string
  ) => {
    const removeProduct = () => {
      const onConfirm = () => {
        dispatch(
          updateBranchProduct({ categoryId, productId: id, active: false })
        );
        dispatch(showSuccess(productName + " removed from your product list"));
        dispatch(closeModal());
        dispatch(closeConfirm());
      };

      const onCancel = () => {
        dispatch(closeConfirm());
      };

      dispatch(
        openConfirm({
          title: "Remove " + productName + "?",
          message:
            "Are you sure you want to remove this product from your branch?",
          show: true,
          data: {
            onConfirm: onConfirm,
            onCancel: onCancel,
          },
        })
      );
    };

    dispatch(
      openModal({
        id: ModalID.Product,
        data: {
          productId: id,
          branchId: branch?.id,
          basketCheck: true,
          addToBasketCallback: () => dispatch(closeModal()),
          onRemoveProductClick: removeProduct,
          skipText: "Close",
        },
      })
    );
  };

  const searchProductOnChange = (value: string) => {
    dispatch(setSearchString(value));
  };

  useEffect(() => {
    if (products.searchString.length > 2) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const id = setTimeout(() => {
        dispatch(
          searchProducts({
            departmentId: department!.id,
            searchText: products.searchString,
          })
        );
      }, 500);
      setTimeoutId(id);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [products.searchString]);


  useEffect(() => {
    if (isMobile) {
      setColumnCount(2);
    }
    else if (isTablet) {
      setColumnCount(5);
    }
    else {
      setColumnCount(10);
    }
  }, [isMobile, isTablet])
  return (
    <TableContainer>
      <ScrollableCard
        background={theme.application.product.background}
        scrollbar={theme.application.scrollBar}
      >
        <ActiveArea>
          <SearchInput>
            <TextInput
              id="btnSearchProducts"
              label="Search Products"
              value={products?.searchString}
              onChange={(event) => searchProductOnChange(event.target.value)}
              iconID={ICON_ID.Search}
            ></TextInput>
          </SearchInput>

          {products.searchLoading && <LoadingSpinner></LoadingSpinner>}
          {products.searchResults && (
            <Category>
              <CategoryTitle>
                {" "}
                Search Results ({products.searchResults.count})
              </CategoryTitle>

              {products.searchResults.count == 0 ? (
                <EmptySearch>
                  No products found matching "{products.searchString}"
                </EmptySearch>
              ) : (
                <ProductList
                  categoryId={-1}
                  products={products.searchResults}
                  onClick={handleProductClick}
                  selectedProductIds={basket?.basketItems?.items.map((x) => x.productId)}
                  columns={columnCount}
                ></ProductList>
              )}
            </Category>
          )}

          {products.categories?.categories.map((category) => (
            <Category key={`category_${category.id}`}>
              <CategoryTitle onClick={() => handleToggleExpand(category)}>
                {category.name}
                <GoogleIcon id={ICON_ID.ExpandDown} size={ICON_SIZES.Large} />
              </CategoryTitle>
              {category.expand && products.products[category.id] && (
                <ProductList
                  categoryId={category.id}
                  products={products.products[category.id]}
                  onClick={handleProductClick}
                  selectedProductIds={
                    basket?.basketItems?.items?.map((x) => x.productId) ?? []
                  }
                  columns={columnCount}
                />
              )}
            </Category>
          ))}
          {Array.from({ length: placeholdersCount }, (_, i) => (
            <Category
              key={`placeholder_${i}`}
              ref={i === 0 ? loadingRowRef : null}
            >
              <CategoryPlaceholder />
            </Category>
          ))}
        </ActiveArea>
      </ScrollableCard>
    </TableContainer>
  );
}

const SearchInput = styled.div`
  margin-bottom: 20px;
  padding: 0px 30px;
`;

const EmptySearch = styled.div`
  padding: 10px;
`;

const Category = styled.div``;

const CategoryTitle = styled.div`
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 10px;
  vertical-align: middle;
  border-bottom: 1px solid ${(props) => props.theme.application.border};
  span {
    float: right;
    margin-top: 5px;
  }
`;

const CategoryPlaceholder = styled.div`
  height: 44px;
  margin-bottom: 10px;
  border-radius: 10px;
  background: ${(props) => props.theme.application.placeholder};
  padding: 10px;
  animation: ${blurAnimation} 0.5s infinite;
`;
