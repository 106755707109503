import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationBranch } from "../../../models/organisation/application-branch";
import { RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import UserService from "../../../services/organisation/user-service";

interface BranchState {
  selectedBranch: ApplicationBranch | null;
  branches: ApplicationBranch[] | null;
}

const initialState: BranchState = {
  selectedBranch: null,
  branches: null,
};

export const fetchBranches = createAsyncThunk<
  ApplicationBranch[] | null,
  void,
  { state: RootState }
>("users/getBranches", async (_, { rejectWithValue }) => {
  const userService = new UserService();
  try {
    return await userService.getBranches();
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setSelectedBranch: (
      state,
      action: PayloadAction<ApplicationBranch | null>
    ) => {
      state.selectedBranch = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchBranches.fulfilled,
      (state, action: PayloadAction<ApplicationBranch[] | null>) => {
        if (action.payload) {
          state.branches = action.payload;
        }
      }
    );
  },
});

export const { setSelectedBranch } = branchSlice.actions;

export default branchSlice.reducer;
