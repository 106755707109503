import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import AuthenticationService from "../../../services/authentication/authentication-service";

interface ResetState {
    email: string;
    newPassword: string | null;
    confirmPassword: string | null;
    token: string | null;
    emailSent: boolean;
    passwordChanged: boolean;
    loading: boolean;
    errors: {
        email: string | null;
        password: string | null;
        confirmPassword: string | null;
        pageError: string | null;
    }
}

const initialState: ResetState = {
    email: "",
    newPassword: null,
    confirmPassword: null,
    token: null,
    loading: false,
    emailSent: false,
    passwordChanged: false,
    errors: {
        email: null,
        password: null,
        confirmPassword: null,
        pageError: null,
    },
}

export const sendResetLink = createAsyncThunk<void, void, { state: RootState, dispatch: AppDispatch }>(
    'reset/resetLink',
    async (_, thunkApi) => {
        const state = thunkApi.getState();
        try {
            const authenticationService = new AuthenticationService();
            await authenticationService.sendReset(state.reset.email, state.reset.token, state.reset.newPassword)
        }
        catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return thunkApi.rejectWithValue(apiError)
        }
    }
);


export const resetSlice = createSlice({
    name: 'reset',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.newPassword = action.payload;
        },
        setConfirmPassword: (state, action: PayloadAction<string>) => {
            state.confirmPassword = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setErrors: (state, action: PayloadAction<{ email: string | null; password: string | null; confirmPassword: string | null; pageError: string | null }>) => {
            state.errors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sendResetLink.pending, (state) => {
            // Handle pending state if needed
            state.loading = true;
        });
        builder.addCase(sendResetLink.fulfilled, (state) => {
            // Handle success state if needed
            state.loading = false;
            if (!state.token) {
                state.emailSent = true;
            } else {
                state.passwordChanged = true;
            }
        });
        builder.addCase(sendResetLink.rejected, (state, action) => {
            // Handle failure state if needed
            const message = action.payload as string;
            state.loading = false;
            state.errors.pageError = message
        });

    }
});

export const { setEmail, setPassword, setConfirmPassword, setErrors, setToken } = resetSlice.actions;

export default resetSlice.reducer

