import { useEffect } from "react";
import TableView, {
  ColumnConfig,
} from "../../../components/controls/table-view";
import {
  SmallTextContainer,
  TextContainer,
} from "../../../styles/shared/table";
import Button from "../../../components/controls/button";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  fetchSupplierProducts,
  insertSupplierProductPrice,
  resetSupplierProducts,
  setNewPrice,
  setSearch,
  toggleEditPrice,
} from "../../../store/features/supplier/supplier-product-slice";
import {
  ProductContainer,
  ProductDescription,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../../styles/shared/product";
import Image from "../../../components/controls/image";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../../components/icons/google-icon";
import { currencyFormatter } from "../../../helpers/formatters";
import styled from "styled-components";
import { ModalID } from "../../../constants/modal-constants";
import { openModal } from "../../../store/features/modal/modal-slice";
import EditableField from "../../../components/controls/editable-field";
import { openDrawer } from "../../../store/features/drawer/drawer-slice";
import { DrawerID } from "../../../constants/drawer-constants";
interface SupplierProductProps {
  supplierId: number;
}
export default function SupplierProducts({ supplierId }: SupplierProductProps) {
  const products = useAppSelector((state) => state.supplierProducts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetSupplierProducts());
    return () => {

      dispatch(setSearch(""))
    };
  }, []);

  const fetchMoreData = async (search: boolean) => {
    await dispatch(fetchSupplierProducts({ supplierId, search, unmappedOnly: false }));
  };

  const renderActionButton = (row: any) => (
    <Button
      variant="outlined"
      id={row.name}
      label="Edit"
      isPrimary={true}
      fullWidth={false}
      onClick={() =>
        dispatch(
          openDrawer({
            id: DrawerID.SupplierProduct,
            anchor: "right",
            data: {
              id: row.id,
              headerText: `Edit ${row.name}`,
            },
          })
        )
      }
    />
  );

  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    productId: {
      hidden: true,
    },
    supplierName: {
      hidden: true,
    },
    supplierId: {
      hidden: true,
    },
    size: {
      hidden: true,
    },
    description: {
      hidden: true,
    },
    image: {
      hidden: true,
    },
    newPrice: {
      hidden: true,
    },
    editPrice: {
      hidden: true,
    },
    plu: {
      hidden: true,
    },
    sizeUnit: {
      hidden: true,
    },
    reference: {
      hidden: true,
    },
    name: {
      order: 1,
      name: "Product",
      colspan: 2,
      render: (value: string, row: any) => (
        <ProductContainer>
          <ProductImage>
            {row.productId ? (
              <Image image={row.image}></Image>
            ) : (
              <GoogleIcon
                tooltip="Not mapped"
                id={ICON_ID.QuestionMark}
                isWarning
                size={ICON_SIZES.Large}
              />
            )}
          </ProductImage>
          <ProductNameContainer>
            <ProductTitle>{value} {row.reference ? ` - (${row.reference})` : ""}</ProductTitle>
            <ProductDescription>
              {row.size} {row.sizeUnit}

              {row.description && " - " + row.description}
            </ProductDescription>
            {row.plu && <SmallTextContainer>Plu: {row.plu}</SmallTextContainer>}
          </ProductNameContainer>
        </ProductContainer>
      ),
    },
    price: {
      order: 2,
      colspan: 2,
      render: (value: number | null, row: any) => {
        return (
          <EditableField
            type="currency"
            value={row.newPrice}
            label="Price"
            isEditing={row.editPrice || false}
            displayValue={value ? currencyFormatter.format(value) : "Not set"}
            onChange={(value) =>
              dispatch(
                setNewPrice({
                  id: row.id,
                  price: value as number,
                })
              )
            }
            onSave={() => dispatch(insertSupplierProductPrice(row.id))}
            onCancel={() => dispatch(toggleEditPrice(row.id))}
            onEdit={() => dispatch(toggleEditPrice(row.id))}
          />
        );
      },
    },
    updated: {
      hideOnMobile: true,
      render: (value: Date) => (
        <TextContainer>
          {new Date(value).toLocaleDateString()}{" "}
          {new Date(value).toLocaleTimeString()}
        </TextContainer>
      ),
    },
    active: {
      hideOnMobile: true,
      render: (value: boolean) => (
        <TextContainer>
          {value ? (
            <GoogleIcon
              tooltip="Active"
              id={ICON_ID.CheckCircle}
              size={ICON_SIZES.Large}
              isSuccess
            />
          ) : (
            <GoogleIcon
              tooltip="Disabled"
              id={ICON_ID.Close}
              size={ICON_SIZES.Large}
              isDanger
            />
          )}
        </TextContainer>
      ),
    },
  };
  return (
    <TableContainer>
      <TableView
        id="tblProducts"
        emptyText="No products"
        fetchMoreData={(search) => fetchMoreData(search ?? false)}
        totalCount={products.products?.count ?? 0}
        data={products?.products?.products ?? []}
        columnConfig={columnConfig}
        actionButton={renderActionButton}
        showHeaders={true}
        searchText={products.searchText}
        setSearchText={(value) => dispatch(setSearch(value))}
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  flex: 0 0 70vh;
  min-height: 0; 
`;
