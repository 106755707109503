import React from "react";
import Dropdown from "./dropdown";
import TextInput from "./text-input";
import Button from "./button";
import { ICON_ID } from "../icons/google-icon";
import styled from "styled-components";
import { SelectChangeEvent } from "@mui/material/Select";
import CurrencyInput from "./currency-input";
interface EditableFieldProps {
  type: "text" | "dropdown" | "currency" | "number";
  value: string | number;
  error?: string | null;
  options?: { value: string; label: string }[];
  label: string;
  isEditing: boolean;
  displayValue?: string;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: string | number) => void;
  onEdit: () => void;
}

const EditableField: React.FC<EditableFieldProps> = ({
  type,
  value,
  error,
  options,
  label,
  isEditing,
  displayValue,
  onSave,
  onCancel,
  onChange,
  onEdit,
}) => {
  return (
    <>
      {isEditing ? (
        <FieldContainer>
          {type === "text" || type === "number" ? (
            <TextInput
              label={label}
              type={type}
              id={`${label}-input`}
              value={value as string}
              error={error}
              multiline={type == "text"}
              onChange={(event) => onChange(event.target.value)}
              sx={{ marginTop: "0px", marginBottom: "0px" }}
            />
          ) : type === "dropdown" ? (
            <Dropdown
              label={label}
              id={`${label}-dropdown`}
              value={value as string}
              options={options || []}
              variant="outlined"
              onChange={(e: SelectChangeEvent<unknown>) =>
                onChange(e.target.value as string)
              }
              autoWidth={false}
              multiple={false}
              native={false}
            />
          ) : type === "currency" ? (
            <CurrencyInput
              label={label}
              value={value}
              onChange={(event) => {
                const newValue = parseFloat(event.target.value);
                onChange(newValue);
              }}
            />
          ) : null}
          <VerticalIcons>
            <Button
              variant="text"
              id={`btnSave${label}`}
              label=""
              icon={ICON_ID.Save}
              isPrimary={true}
              fullWidth={false}
              onClick={onSave}
            />
            <Button
              variant="text"
              id={`btnCancel${label}`}
              label=""
              icon={ICON_ID.Close}
              isPrimary={true}
              fullWidth={false}
              onClick={onCancel}
            />
          </VerticalIcons>
        </FieldContainer>
      ) : (
        <EditButtonContainer>
          <TextContainer>{displayValue}</TextContainer>
          <EditButton
            variant="text"
            id={`btnEdit${label}`}
            label=""
            icon={ICON_ID.Edit}
            isPrimary={true}
            fullWidth={false}
            onClick={onEdit}
          />
        </EditButtonContainer>
      )}
    </>
  );
};

export default EditableField;
const VerticalIcons = styled.div`
  display: flex;
  flex-direction: column;

  button {
    span {
      font-size: 1.1rem;
    }
  }
`;
const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const EditButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const EditButton = styled(Button)`
  flex-shrink: 0;
`;
const TextContainer = styled.span`
  flex-grow: 1;
  white-space: break-spaces;
`;


