import { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { closeModal } from "../../store/features/modal/modal-slice";
import {
  fetchFiles,
  setSelectedFile,
  deleteFile,
  uploadFile,
  resetFiles,
  setSearch,
} from "../../store/features/organisation/image-gallery-slice";
import TextInput from "../../components/controls/text-input";
import { useAppSelector } from "../../hooks/useAppSelector";
import Image from "../../components/controls/image";
import { ApplicationFile } from "../../models/organisation/application-file";
import {
  openConfirm,
  closeConfirm,
} from "../../store/features/notification/confirm-slice";
import { showSuccess } from "../../components/notification/toastr-actions";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import LoadingSpinner from "../../components/controls/loading-spinner";
import { LinearProgress, Alert, AlertTitle } from "@mui/material";
import FileUploader from "../../components/controls/file-uploader";
interface ImageGalleryModalProps {
  updateImage: (imagePath: string) => void;
}
interface FileProgress {
  progress: number;
  fileName: string;
}
export default function ImageGalleryModal({
  updateImage,
}: ImageGalleryModalProps) {
  const dispatch = useAppDispatch();
  const files = useAppSelector((state) => state.imageGallery).files;
  const searchText = useAppSelector((state) => state.imageGallery.searchText);
  const [loading, setLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState<FileProgress>({
    progress: 0,
    fileName: "",
  });
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const selectedFile = useAppSelector(
    (state) => state.imageGallery
  ).selectedFile;
  const loadingRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(resetFiles());
    loadfiles();
  }, []);
  const handleImageClick = (file: ApplicationFile) => {
    dispatch(setSelectedFile(file));
  };

  const loadfiles = async (search?: boolean) => {
    await dispatch(fetchFiles({ search: search ?? false }));
  };
  useInfiniteScroll({
    dataLength: files?.files?.length || 0,
    totalCount: files?.count || 0,
    fetchMoreData: (search) => loadfiles(search),
    setLoading,
    loading,
    loadingRowRef,
  });

  const handleDeletefile = () => {
    const onConfirm = () => {
      dispatch(deleteFile(selectedFile!.name)).then((response) => {
        dispatch(closeConfirm());
        if (response.meta.requestStatus == "fulfilled") {
          dispatch(showSuccess(`${selectedFile?.displayName} deleted successfully.`));
        }

      })

    };
    const onCancel = () => {
      dispatch(closeConfirm());
    };
    dispatch(
      openConfirm({
        title: "Remove " + selectedFile?.displayName + "?",
        message: "Are you sure you want to delete this image from the gallery?",
        show: true,
        data: {
          onConfirm: onConfirm,
          onCancel: onCancel,
        },
      })
    );
  };

  const handleSelectfile = () => {
    updateImage(selectedFile?.fullPath || "");
    dispatch(closeModal());
  };

  const handleFilesUpload = async (files: FileList) => {
    const validImageTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/svg+xml",
    ];

    const validFiles = Array.from(files).filter((file) =>
      validImageTypes.includes(file.type)
    );
    const invalidFiles = Array.from(files).filter(
      (file) => !validImageTypes.includes(file.type)
    );

    setUploadErrors(
      invalidFiles.map((file) => `${file.name} is not a supported file type.`)
    );

    const initialProgress = { progress: 0, fileName: "" };
    setFileProgress(initialProgress);

    const totalFiles = validFiles.length;
    for (let i = 0; i < totalFiles; i++) {
      const file = validFiles[i];
      if (file) {
        setFileProgress({
          progress: Math.round(((i + 1) / totalFiles) * 100),
          fileName: file.name,
        });

        await dispatch(uploadFile(file));
      }
    }
    setFileProgress({ progress: 0, fileName: "" });
    setUploadErrors([]);
  };

  // Prevents the default browser behavior during a drag-over event,
  // allowing the drag-and-drop interaction for file uploads.
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      handleFilesUpload(e.dataTransfer.files);
    }
  };
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const searchData = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLoading(true);
    if (setSearch && loadfiles) {
      dispatch(setSearch(event.target.value));
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        loadfiles(true).then(() => {
          setLoading(false);
        });
      }, 2000);
    }
  };
  const handleFileSelected = (files: FileList) => {
    handleFilesUpload(files);
  };
  return (
    <Container>
      <Title>Image Gallery</Title>
      <TextInput
        id="txtSearchImages"
        label="Find images"
        value={searchText}
        onChange={(event) => searchData(event)}
      ></TextInput>
      <DragDrop onDragOver={handleDragOver} onDrop={handleDrop}>
        <FileUploader
          buttonText="Upload Images"
          placeholder="Select a file..."
          acceptedFileTypes=".png,.jpg,.jpeg,.gif,.svg"
          showSelectedFile={false}
          multiple={true}
          onFileSelected={handleFileSelected}
        />
        Drag and drop files to upload
        {Object.keys(fileProgress).length > 0 && fileProgress.progress > 0 && (
          <ProgressContainer>
            <p>{fileProgress.fileName}</p>
            <LinearProgress
              variant="determinate"
              value={fileProgress.progress}
            />
          </ProgressContainer>
        )}
        {uploadErrors.length > 0 && (
          <ErrorContainer>
            <Alert severity="warning">
              <AlertTitle>Validation Errors</AlertTitle>
              {uploadErrors.map((error, idx) => (
                <div key={idx}>{error}</div>
              ))}
            </Alert>
          </ErrorContainer>
        )}
        <GalleryContainer>
          {!files?.files ? (
            <LoadingSpinner />
          ) : files.files.length > 0 ? (
            <Products>
              {files.files.map((file) => (
                <Product isSelected={selectedFile?.name === file.name}
                  key={`image${file.name}`}
                  onClick={() => handleImageClick(file)}
                >
                  <ProductImage  >
                    <ImageWrapper >
                      <Image image={file.fullPath} />
                    </ImageWrapper>
                  </ProductImage>
                  <ProductName>{file.displayName}</ProductName>
                </Product>
              ))}
              {files.count != files.files.length && (
                <Product isSelected={false} ref={loadingRowRef}>
                  <ProductImage >
                    <ImageWrapper ></ImageWrapper>
                    <ProductName></ProductName>
                  </ProductImage>
                </Product>
              )}
              {files.loading && (
                <LoadingRow>
                  <LoadingSpinner />
                </LoadingRow>
              )}
            </Products>
          ) : (
            <p>No images found</p>
          )}
        </GalleryContainer>
        <FileInfoContainer>
          <SelectedFileName>Selected: {selectedFile?.displayName}</SelectedFileName>
          <ButtonContainer>
            <Button
              id="btnDeleteFile"
              label="Delete File"
              variant="outlined"
              isDanger
              disabled={!selectedFile}
              onClick={() => handleDeletefile()}
            />
            <Button
              id="btnSelect"
              label="Select"
              variant="contained"
              disabled={!selectedFile}
              onClick={() => handleSelectfile()}
            />
          </ButtonContainer>
        </FileInfoContainer>
      </DragDrop>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 10px;
  border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};
`;
const GalleryContainer = styled.div`
  margin-top: 10px;
  border: 1px solid ${(props) => props.theme.application.border};
  max-width: 500px;
  padding: 10px;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 50vh;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.application.product.background};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.application.scrollBar};
    border: 2px solid ${(props) => props.theme.application.scrollBar};
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    max-height: 40vh;
    max-width: 400px;
  }
`;

const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const Product = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction:column;
  justify-content: center;
    transition: all 0.3s ease;

border-radius:8px;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
  }

      border: ${({ isSelected, theme }) =>
    isSelected
      ? `2px solid ${theme.application.scheme.primary}`
      : ``};
`;

const ProductImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 80px;

  cursor:pointer;

  img {
    width: 100%;
     height:80px;
    object-fit: contain;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height:80px;

`;

const ProductName = styled.span`
  text-align: center;
  font-size: 0.6rem;
  margin-top: 10px;
  word-wrap: break-word;
  font-weight:600;
  background: ${(props) => props.theme.application.hover};
  padding:3px;
`;
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  gap: 10px;
`;
const FileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const SelectedFileName = styled.span`
  font-size: 1rem;
  font-weight:bold;
  color: ${(props) => props.theme.application.text};
  flex: 1;
  text-align: start;
  word-wrap: break-word;
  width: 20px;
`;
const DragDrop = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  color: ${(props) => props.theme.application.text};
`;
const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
`;
const LoadingRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`;
