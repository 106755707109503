import TableView, {
  ColumnConfig,
} from "../../../components/controls/table-view";
import Button from "../../../components/controls/button";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { showSuccess } from "../../../components/notification/toastr-actions";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  setNewValue,
  toggleEdit,
  applyNewValues,
  resetProductState,
  bulkUploadProducts,
} from "../../../store/features/supplier/supplier-product-upload-slice";
import {
  ProductContainer,
  ProductNameContainer,
  ProductTitle,
} from "../../../styles/shared/product";
import { ICON_ID } from "../../../components/icons/google-icon";
import { currencyFormatter } from "../../../helpers/formatters";
import styled from "styled-components";
import { ModalID } from "../../../constants/modal-constants";
import { openModal } from "../../../store/features/modal/modal-slice";
import { removeProductByName } from "../../../store/features/supplier/supplier-product-upload-slice";
import EditableField from "../../../components/controls/editable-field";
import { ApplicationSizeUnit } from "../../../models/product/application-size-unit";
import { ApplicationSupplierProductsList } from "../../../models/supplier/application-supplier-product-list";

interface UploadSupplierProductProps {
  supplierId: number;
  handleUploadSuccess: (data: ApplicationSupplierProductsList) => void;
}

export default function UploadSupplierProducts({
  supplierId,
  handleUploadSuccess,
}: UploadSupplierProductProps) {
  const products = useAppSelector((state) => state.supplierProductUpload);
  const dispatch = useAppDispatch();
  const renderActionButton = (row: any) => (
    <Button
      variant="outlined"
      id={row.name}
      label="Remove"
      isDanger={true}
      fullWidth={false}
      onClick={() => handleRemoveProduct(row.name)}
    />
  );

  const handleUploadProducts = async () => {
    const response = await dispatch(
      bulkUploadProducts({
        supplierId: supplierId,
        products: products?.products,
      })
    );
    if (response) {
      const data = response.payload as ApplicationSupplierProductsList
      dispatch(showSuccess(`Products uploaded successfully!`));
      handleUploadSuccess(data);
      dispatch(resetProductState());
    }
  };

  const handleRemoveProduct = (productName: string) => {
    dispatch(removeProductByName(productName));
  };

  const handleCloseButtonClick = (
    name: string,
    field: "Price" | "Size" | "SizeUnit" | "Reference",
    value: number | string
  ) => {
    dispatch(toggleEdit({ name, field }));
    dispatch(setNewValue({ name, field, value }));
  };

  const columnConfig: { [key: string]: ColumnConfig } = {
    newPrice: {
      hidden: true,
    },
    editPrice: {
      hidden: true,
    },
    editSize: {
      hidden: true,
    },
    newSize: {
      hidden: true,
    },
    newSizeUnit: {
      hidden: true,
    },
    newReference: {
      hidden: true,
    },
    editSizeUnit: {
      hidden: true,
    },
    editReference: {
      hidden: true
    },
    name: {
      name: "Product",
      order: 1,
      colspan: 2,
      render: (value: string, row: any) => (
        <ProductContainer>
          <ProductNameContainer>
            <ProductTitle>{value}</ProductTitle>
            <div>{row.size}</div>
          </ProductNameContainer>
        </ProductContainer>
      ),
    },
    reference: {
      order: 2,
      render: (value: string | null, row: any) => {
        return (
          <EditableField
            type="text"
            value={row.newReference}
            isEditing={row.editReference || false}
            label="Reference"
            displayValue={value ? `${value}` : "Not set"}
            onChange={(value) =>
              dispatch(
                setNewValue({
                  name: row.name,
                  field: "Reference",
                  value: value,
                })
              )
            }
            onSave={() =>
              dispatch(applyNewValues({ names: row.name, field: "Reference" }))
            }
            onCancel={() =>
              handleCloseButtonClick(row.name, "Reference", row.price)
            }
            onEdit={() =>
              dispatch(toggleEdit({ name: row.name, field: "Reference" }))
            }
          />
        );
      },
    },
    price: {
      order: 3,
      render: (value: number | null, row: any) => {
        return (
          <EditableField
            type="currency"
            value={row.newPrice}
            isEditing={row.editPrice || false}
            displayValue={value ? currencyFormatter.format(value) : "Not set"}
            label="Price"
            onChange={(value) =>
              dispatch(
                setNewValue({
                  name: row.name,
                  field: "Price",
                  value: value,
                })
              )
            }
            onSave={() =>
              dispatch(applyNewValues({ names: row.name, field: "Price" }))
            }
            onCancel={() =>
              handleCloseButtonClick(row.name, "Price", row.price)
            }
            onEdit={() =>
              dispatch(toggleEdit({ name: row.name, field: "Price" }))
            }
          />
        );
      },
    },
    size: {
      order: 4,
      render: (value: number | null, row: any) => {
        return (
          <EditableField
            type="text"
            value={row.newSize}
            isEditing={row.editSize || false}
            displayValue={value ? `${value}` : "Not set"}
            label="Size"
            onChange={(value) =>
              dispatch(
                setNewValue({
                  name: row.name,
                  field: "Size",
                  value: value,
                })
              )
            }
            onSave={() =>
              dispatch(applyNewValues({ names: row.name, field: "Size" }))
            }
            onCancel={() => handleCloseButtonClick(row.name, "Size", row.size)}
            onEdit={() =>
              dispatch(toggleEdit({ name: row.name, field: "Size" }))
            }
          />
        );
      },
    },
    sizeunit: {
      name: "Unit",
      order: 5,
      render: (value: number | null, row: any) => {
        return (
          <EditableField
            type="dropdown"
            value={row.newSizeUnit}
            options={Object.values(ApplicationSizeUnit).map((x) => ({ value: x, label: x }))}
            isEditing={row.editSizeUnit || false}
            displayValue={value ? `${value}` : "Not set"}
            label="Unit"
            onChange={(value) =>
              dispatch(
                setNewValue({
                  name: row.name,
                  field: "SizeUnit",
                  value: value,
                })
              )
            }
            onSave={() =>
              dispatch(applyNewValues({ names: row.name, field: "SizeUnit" }))
            }
            onCancel={() => handleCloseButtonClick(row.name, "SizeUnit", row.sizeUnit)}
            onEdit={() =>
              dispatch(toggleEdit({ name: row.name, field: "SizeUnit" }))
            }
          />
        )
      },
    },
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          icon={ICON_ID.Upload}
          label="Upload Products by CSV"
          id="btnUploadProducts"
          onClick={() =>
            dispatch(
              openModal({
                id: ModalID.UploadSupplierProduct,
                data: { id: supplierId },
              })
            )
          }
        />
      </div>
      <TableContainer>
        <TableView
          id="tblUploadSupplieProducts"
          emptyText="Upload Products by CSV"
          totalCount={products?.products?.length ?? 0}
          data={products.products ?? []}
          columnConfig={columnConfig}
          actionButton={renderActionButton}
          showHeaders={true}
        />
      </TableContainer>
      {products.products?.length > 0 && (
        <ButtonContainer>
          <Button
            variant="contained"
            label="Upload Products"
            id="btnUploadProducts"
            onClick={handleUploadProducts}
          />
        </ButtonContainer>
      )}
    </>
  );
}

const TableContainer = styled.div`
  flex: 0 0 60vh;
  min-height: 0; /* workaround for scrolling fix when flipping to column flex */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
