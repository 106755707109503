import client from "../../external/open-api";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationApprovalStatus } from "../../models/order/application-approval-status";
import { ApplicationApproval } from "../../models/order/approval/application-approval";
import { ApplicationApprovalList } from "../../models/order/approval/application-approval-list";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
import { ApplicationSort } from "../../models/shared/application-sort";
import IApprovalService from "./approval-service-interface";

export default class ApprovalService implements IApprovalService {

    async get(currentPosition: number, fetchNext: number, sort: ApplicationSort): Promise<ApplicationApprovalList> {
        const { data, error } = await client.GET("/v{version}/api/approval", {
            params: {
                path: { version: "1" },  // Path parameter
                query: {
                    "Paging.CurrentPosition": currentPosition,
                    "Paging.FetchNext": fetchNext,
                    "Paging.FetchAll": false,
                    Sort: sort,
                },
            },
        });

        if (!data || error) {
            throw new ApplicationError("Api called failed");
        }

        const mappedResponse = await this.handleResponse(data, "Failed to fetch approvals");

        const approvals = mappedResponse.approvals?.map<ApplicationApproval>(x => ({
            id: x.id ?? 0,
            orderId: x.orderId ?? 0,
            productId: x.productId ?? 0,
            productName: x.productName ?? "",
            productDescription: x.productDescription ?? "",
            productImage: x.productImage ?? "",
            size: x.size ?? 0,
            sizeUnit: mapStringToEnum(ApplicationSizeUnit, x.sizeUnit ?? "Unknown"),
            quantity: x.quantity ?? 0,
            approvalStatus: mapStringToEnum(ApplicationApprovalStatus, x.approvalStatus ?? ""),
            username: x.username ?? "",
            created: x.created ? new Date(x.created) : new Date(),
            branchId: x.branchId ?? 0,
            branchName: x.branchName ?? "",
            notes: x.notes ?? "",
        })) ?? [];

        return {
            approvals,
            count: mappedResponse?.count || 0,
            currentPosition: mappedResponse?.currentPosition || 0,
        };

    }

    async getApproval(orderLineId: number): Promise<ApplicationApproval> {
        const { data, error } = await client.GET("/v{version}/api/approval/{orderLineId}", {
            params: {
                path: { version: "1", orderLineId: orderLineId },  // Path parameter                
            },
        });

        if (!data || error) {
            throw new ApplicationError("Api called failed");
        }

        const mapped = await this.handleResponse(data, "Failed to fetch approval");

        return {
            id: mapped.id ?? 0,
            orderId: mapped.orderId ?? 0,
            productId: mapped.productId ?? 0,
            productName: mapped.productName ?? "",
            productDescription: mapped.productDescription ?? "",
            productImage: mapped.productImage ?? "",
            size: mapped.size ?? 0,
            sizeUnit: mapStringToEnum(ApplicationSizeUnit, mapped.sizeUnit ?? "Unknown"),
            quantity: mapped.quantity ?? 0,
            approvalStatus: mapStringToEnum(ApplicationApprovalStatus, mapped.approvalStatus ?? ""),
            username: mapped.username ?? "",
            created: mapped.created ? new Date(mapped.created) : new Date(),
            branchId: mapped.branchId ?? 0,
            branchName: mapped.branchName ?? "",
            notes: mapped.notes ?? "",
        };
    }

    async updateApproval(orderLineId: number, quantity: number, approvalStatus: ApplicationApprovalStatus): Promise<void> {

        const { data, error } = await client.PATCH("/v{version}/api/approval/{orderLineId}", {
            params: {
                path: { version: "1", orderLineId: orderLineId },  // Path parameter                
            },
            body: {
                quantity,
                approvalStatus
            }
        });

        if (!data || error) {
            throw new ApplicationError("Update approval api called failed");
        }

        await this.handleResponse(data, "Failed to fetch approval");
    }

    private async handleResponse<T>(response: { success?: boolean | undefined, data?: T | undefined }, errorMessage: string): Promise<T> {
        if (!response.success) {
            throw new ApplicationError(errorMessage);
        }
        return response.data!;
    }
}